<template>
  <v-card class="pt-0 px-2 pb-2 up-card customer-payment">
    <v-card-title>
      <h2 class="heading">Payment Detail</h2>
    </v-card-title>
    <v-divider></v-divider>
    <div class="amount-info">
      <v-layout wrap grey lighten-4 my-2 px-3 pb-3>
        <v-flex xs12>
          <div class="prepenpound-icon">
            <span class="primary--text headlinefont title pound-icon">&pound; &nbsp;</span>
            <v-text-field
              v-model="amount"
              prepend-icon="close"
              label="Amount"
              :readonly="transactionComplete"
            ></v-text-field>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div class="payment-form px-3">
      <v-layout row wrap>
        <v-flex v-if="job.emergencies.length > 1" xs12>
          <v-select
            v-model.trim="emergencyTypeId"
            v-validate="'required'"
            label="Emergency Type"
            :items="job.emergencies"
            item-text="typeDescription"
            item-value="typeId"
            data-vv-name="EmergencyType"
            data-vv-scope="frmCustomerToPayPreview"
            required
            class="required"
            :error-messages="validationMessage('EmergencyType')"
            :readonly="transactionComplete"
            @change="onEmergencyTypeChange($event)"
          ></v-select>
        </v-flex>
        <v-flex v-if="job.emergencies.length > 1" xs12>
          <v-select
            v-model.trim="emergencyDetailId"
            v-validate="'required'"
            label="Emergency Detail"
            :items="jobEmergencyDetailList"
            item-text="detailDescription"
            item-value="detailId"
            data-vv-name="EmergencyDetail"
            data-vv-scope="frmCustomerToPayPreview"
            required
            class="required"
            :error-messages="validationMessage('EmergencyDetail')"
            :readonly="transactionComplete"
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model="customerToPayModel.cardHolderName"
            label="Card Holder Name"
            :readonly="transactionComplete"
          ></v-text-field>
        </v-flex>
        <v-flex md12>
          <v-checkbox
            v-model="addressCheckbox"
            label="Billing Address same as job address"
            color="primary"
            hide-details
            :disabled="transactionComplete"
          ></v-checkbox>
        </v-flex>
        <v-layout v-if="!addressCheckbox" row wrap>
          <v-flex xs6 class="pr-2">
            <v-text-field
              v-model="customerToPayModel.billingAddress.line1"
              label="Address Line1"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="pl-2">
            <v-text-field
              v-model="customerToPayModel.billingAddress.line2"
              label="Address Line2"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="pr-2">
            <v-text-field
              v-model="customerToPayModel.billingAddress.city"
              label="City"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="pl-2">
            <v-text-field
              v-model="customerToPayModel.billingAddress.state"
              label="State"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="pr-2">
            <v-text-field
              v-model="customerToPayModel.billingAddress.country"
              label="Country"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 class="pl-2">
            <v-text-field
              id="postal-code"
              ref="postcode"
              v-model="customerToPayModel.billingAddress.postcode"
              name="postal_code"
              label="Postal code"
              :readonly="transactionComplete"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12>
          <v-switch
            v-model="manualPaymentToggle"
            class="justify-end mt-3"
            color="primary"
            label="Take manual payment"
            :disabled="paymentToggleDisabled"
          />
        </v-flex>
        <v-layout v-show="manualPaymentToggle" wrap grey lighten-4 px-3 pb-3>
          <v-flex v-if="!transactionComplete" xs12 :class="transactionComplete ? 'payment-card-disabled' : ''">
            <div class="group input-group input-fc">
              <div ref="card" class="field input--focus"></div>
              <label>Card</label>
            </div>
          </v-flex>
          <v-flex v-if="transactionComplete" xs12 mt-3 mb-1>
            <p class="mb-2">
              <v-icon>credit_card</v-icon>
              <b>**** **** **** {{ customerToPayModel.cardLastFourDigit }}</b>
            </p>
          </v-flex>
        </v-layout>
        <v-layout v-if="!manualPaymentToggle && (currentCallSid || transactionComplete)" wrap grey lighten-4>
          <AssistedPay
            :callSid="currentCallSid"
            :showCompleted="transactionComplete"
            @inProgressChanged="agentAssistedProgressChanged"
            @clearErrorMessage="clearErrorMessage"
          />
        </v-layout>
        <v-layout v-if="!manualPaymentToggle && !(currentCallSid || transactionComplete)" wrap grey lighten-4>
          <span class="ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text">
            &#33; &nbsp; You must be on a call to take payment details
          </span>
        </v-layout>
        <v-flex xs12>
          <v-checkbox
            v-model="requiredReceipt"
            label="Do you want a payment receipt emailed to you?"
            color="primary"
            hide-details
            :readonly="transactionComplete"
            :disabled="transactionComplete"
            @change="onRequiredReceiptOptionChange(requiredReceipt)"
          ></v-checkbox>
        </v-flex>
        <v-flex v-if="requiredReceipt" xs12>
          <v-text-field
            v-model.trim="customerToPayModel.cardHolderEmail"
            v-validate="'required|max:50|email'"
            label="Email Address"
            data-vv-scope="frmCustomerToPayPreview"
            data-vv-name="Email Address"
            :error-messages="validationMessage('Email Address')"
            required
            class="required"
            :readonly="transactionComplete"
            :disabled="transactionComplete"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 :class="!requiredReceipt ? 'pt-3' : ''">
          <v-text-field
            v-model="customerToPayModel.statementDescription"
            v-validate="{ max: 22, regex: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ]*)$/, required: true }"
            label="Statement Description"
            :readonly="transactionComplete"
            data-vv-scope="frmCustomerToPayPreview"
            data-vv-name="Statement Description"
            :error-messages="validationMessage('Statement Description')"
            required
            class="required"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 :class="!requiredReceipt ? 'pt-3' : ''">
          <v-select
            v-model="paymentType"
            v-validate="'required'"
            data-vv-scope="frmCustomerToPayPreview"
            data-vv-name="Payment Type"
            :items="paymentTypes"
            item-label="label"
            item-value="value"
            label="Payment Type"
            placeholder="Please select a payment type"
            :readonly="transactionComplete"
            :error-messages="validationMessage('Payment Type')"
            required
            class="required sel-paymentType"
          >
            <template #item="{ item }">
              <div class="flex flex-col">
                <div>{{ item.label }}</div>
                <div class="text--secondary text-lg">{{ item.description }}</div>
              </div>
            </template>

            <template #selection="{ item }">
              <span>{{ item.label }}</span>
            </template>
          </v-select>
        </v-flex>
        <v-flex>
          <div v-if="errorMessage" class="mr-3 error-info error--text">{{ errorMessage }}</div>
          <div v-else-if="customerToPayModel.isTransactionCancelled" class="mr-3 error-info error--text">Cancelled</div>
          <div v-else-if="transactionComplete" class="mr-3 error-info success--text">
            <b>Payment successful!</b>
          </div>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex class="text-xs-right">
          <v-btn
            v-if="job.status !== 'Cancelled' && !job.cancellationReason ? !transactionComplete : false"
            color="primary"
            type="submit"
            class="mr-0 btn-payment"
            :disabled="payButtonDisabled"
            :loading="isLoading"
            @click="pay"
          >
            Pay
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import * as R from 'ramda'
import Store from '@/store'
import moment from 'moment'
import Shared from '@/common/shared'
import CustomerToPayModel, { PaymentType, PaymentTypes } from '@/models/claim/CustomerToPayModel'
import storeGetters from '@/storeGetters'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import AddCustomerToPay from '@/models/requests/AddCustomerToPay'
import eventBus from '@/common/bus'
import Emergency from '@/models/Emergency'
import EscalateCTPResponseModel from '@/models/claim/EscalateCTPResponseModel'
import PaymentController from '@/api/paymentController'
import TwilioController from '@/api/twilioController'
import TokenizedPaymentController from '@/api/TokenizedPaymentController'
import Address from '@/models/Address'
import { loadStripe, Stripe, StripeElements, StripeCardElement, CreatePaymentMethodCardData } from '@stripe/stripe-js'
import AssistedPay from '@/components/twilio/assistedPay/AssistedPay.vue'
import JobPermissions from '@/models/job/job-permissions'
import { useAssistedPayStore } from '@/pinia/assisted-pay'

@Component({
  components: { AssistedPay },
})
export default class CustomerToPayPreview extends TimeLineItemBase {
  private get customerToPay(): CustomerToPayModel {
    if (this.itemId) {
      return storeGetters.getCustomerToPayByItemId(this.jobId, this.itemId)
    }
    return this.customerToPayModel
  }

  private set amount(newValue: number) {
    this.customerToPayModel.amount = Number(newValue)
  }

  private get amount(): number {
    return this.customerToPayModel.amount
  }

  private get payButtonDisabled(): boolean {
    const disabled = this.transactionComplete || this.amount <= 0 || this.isLoading
    return !this.manualPaymentToggle ? disabled || !this.assistedPayStore.paymentToken : disabled
  }

  private get jobEmergencyDetailList(): Emergency[] {
    if (this.job) {
      if (!this.emergencyTypeId) {
        return this.job.emergencies
      }
      return this.job.emergencies.filter((e) => {
        return e.typeId === this.emergencyTypeId
      })
    }
    return []
  }
  private stripe: Stripe
  private elements: StripeElements
  private card: StripeCardElement
  private addressCheckbox = true
  private customerToPayModel: CustomerToPayModel = new CustomerToPayModel(this.jobId)
  private isLoading = false
  private errorMessage = ''
  private emergencyTypeId: number | null = null
  private emergencyDetailId: number | null = null
  private escalateCTPResponseModel: EscalateCTPResponseModel | null = null
  private escalatedCTPAmount = 0
  private style = {
    base: {
      iconColor: '#666EE8',
      color: '#31325F',
      lineHeight: '36px',
      fontSize: '16px',
      '::placeholder': {
        color: '#c5c5c5',
      },
    },
  }
  private requiredReceipt = true
  public manualPaymentToggle = false
  public agentAssistInProgress = false
  public assistedPaymentInProgress = false
  public paymentType = ''
  public paymentTypes = Object.values(PaymentTypes)
  private assistedPayStore = useAssistedPayStore()

  public get currentCallSid(): string {
    return Store.Instance.state.CurrentCallSid
  }

  public get paymentToggleDisabled(): boolean {
    return (
      this.transactionComplete ||
      !this.canTakeManualPayments ||
      this.assistedPaymentInProgress ||
      !!this.assistedPayStore.paymentToken
    )
  }

  public get transactionComplete(): boolean {
    return !!this.customerToPayModel.isTransactionCompleted
  }

  public get canTakeManualPayments(): boolean {
    const jobPermissions: JobPermissions = this.$store.getters['jobModule/jobPermissions']
    if (jobPermissions) {
      return jobPermissions.canTakeManualPayments
    } else {
      return false
    }
  }

  @Watch('addressCheckbox')
  public setBillingAddress() {
    if (this.addressCheckbox) {
      this.customerToPayModel.billingAddress = this.customerToPayModel.jobAddress
    } else {
      this.customerToPayModel.billingAddress = new Address()
    }
  }

  private created() {
    this.setBillingAddress()

    if (this.customerToPay && this.customerToPay.isTransactionCompleted === true) {
      this.customerToPayModel = { ...this.customerToPay }

      this.addressCheckbox = !(
        this.customerToPayModel.billingAddress.city ||
        this.customerToPayModel.billingAddress.country ||
        this.customerToPayModel.billingAddress.state
      )
      if (!this.emergencyTypeId || !this.emergencyDetailId) {
        this.setEmergencyDetail(this.customerToPayModel.emergencyId)
      }
      this.requiredReceipt = !!this.customerToPayModel.cardHolderEmail
      this.paymentType = this.customerToPayModel.paymentType
    } else {
      if (this.job) {
        this.customerToPayModel.createdAt = moment.utc(new Date())
        this.customerToPayModel.jobId = this.job.jobId
        this.customerToPayModel.cardHolderName = this.job.clientFullName
        this.customerToPayModel.jobAddress.postcode = this.job.postCode
        this.customerToPayModel.jobAddress.line1 = `${this.job.addressLine1} ${this.job.addressLine2}`
        this.customerToPayModel.jobAddress.line2 = `${this.job.addressLine3} ${this.job.addressLine4}`
        this.customerToPayModel.isTransactionCompleted = false
        this.customerToPayModel.statementDescription =
          this.job.emergencies.length === 1
            ? this.job.emergencies[0].typeDescription
              ? this.job.emergencies[0].typeDescription
              : this.job.emergencies[this.job.emergencies.length - 1].detailDescription
            : ''
        this.customerToPayModel.cardHolderEmail = this.job.email ? this.job.email : ''
        this.customerToPayModel.paymentType = PaymentType[this.paymentType]
      }
    }

    this.assistedPayStore.isPaymentComplete = this.customerToPayModel.isTransactionCompleted
  }

  @Watch('customerToPay')
  private customerToPayChanged(): void {
    this.isLoading = false
    if (this.customerToPay !== this.customerToPayModel) {
      if (this.customerToPay) {
        this.customerToPayModel = { ...this.customerToPay }
      }
    }
    this.addressCheckbox = !(
      this.customerToPayModel.billingAddress.city ||
      this.customerToPayModel.billingAddress.country ||
      this.customerToPayModel.billingAddress.state
    )
    if (this.job) {
      const emergency: Emergency | undefined = this.job.emergencies.find(
        (e) => e.id === this.customerToPayModel.emergencyId
      )
      if (emergency) {
        this.emergencyTypeId = emergency.typeId ? emergency.typeId : null
        this.emergencyDetailId = emergency.detailId ? emergency.detailId : null
      }
    }
  }

  private destroyed() {
    this.assistedPayStore.reset()
  }

  private async mounted() {
    const stripe = await loadStripe(Store.Instance.state.Environment.StripePublishKey)
    if (!stripe) {
      throw new Error('Failed to load stripe.')
    }
    this.stripe = stripe
    this.elements = this.stripe.elements()

    if (!this.customerToPayModel.isTransactionCompleted) {
      this.card = this.elements.create('card', {
        hidePostalCode: true,
        style: this.style,
      })

      this.card.mount(this.$refs.card as HTMLElement)
    }
    eventBus.$on(
      'escalateCTPResponse',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number, emergencyId: string) => {
        this.escalateCTPResponseModel = escalateCTPResponseModel
        this.escalatedCTPAmount = amountNeedToPay ? Math.round(amountNeedToPay * 100) / 100 : 0
        this.amount = this.escalatedCTPAmount
        this.setEmergencyDetail(emergencyId)
      }
    )
    eventBus.$on('paymentOnClosingJob', (amountNeedToPay: number) => {
      this.escalatedCTPAmount = amountNeedToPay ? Math.round(amountNeedToPay * 100) / 100 : 0
      this.amount = this.escalatedCTPAmount
    })
    eventBus.$on(
      'triggerCTPForPolicyChange',
      (escalateCTPResponseModel: EscalateCTPResponseModel, amountNeedToPay: number) => {
        this.escalateCTPResponseModel = escalateCTPResponseModel
        this.escalatedCTPAmount = amountNeedToPay ? Math.round(amountNeedToPay * 100) / 100 : 0
        this.amount = this.escalatedCTPAmount
      }
    )
  }

  private setEmergencyDetail(emergencyId: string) {
    if (this.job) {
      const emergency: Emergency | undefined = this.job.emergencies.find((e) => e.id === emergencyId)
      if (emergency) {
        this.emergencyTypeId = emergency.typeId ? emergency.typeId : null
        this.emergencyDetailId = emergency.detailId ? emergency.detailId : null
        this.customerToPayModel.statementDescription = this.customerToPayModel.statementDescription
          ? this.customerToPayModel.statementDescription
          : emergency.typeDescription
          ? emergency.typeDescription
          : emergency.detailDescription
      }
    }
  }

  private async createPaymentMethod(cardHoldername: string, cardElement: StripeCardElement): Promise<any> {
    const paymentMethodParams: CreatePaymentMethodCardData = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardHoldername,
      },
    }

    const response = await this.stripe.createPaymentMethod(paymentMethodParams)

    if (response.error) {
      throw Error(`Failed to create payment method. ${response.error.message}`)
    }

    return response.paymentMethod
  }

  private async pay() {
    this.errorMessage = ''
    const isValid = await this.validate()

    if (isValid) {
      this.isLoading = true

      try {
        if (!this.manualPaymentToggle) {
          const paymentDetailErrors: string[] = this.assistedPayStore.paymentDetailErrors().map((x) => x.message)
          if (paymentDetailErrors.length) {
            this.errorMessage = paymentDetailErrors.join(',')
            this.isLoading = false
            return
          } else {
            const charge = await TokenizedPaymentController.Pay(
              this.assistedPayStore.paymentToken,
              this.convertPoundsToPence(this.amount),
              this.customerToPayModel.statementDescription,
              this.customerToPayModel.cardHolderEmail,
              this.jobId,
              this.customerToPayModel.jobAddress.postcode,
              this.customerToPayModel.cardHolderName,
              this.assistedPayStore.brand,
              this.customerToPayModel.paymentType
            )

            if (!charge.paid) {
              this.errorMessage = `Unable to process payment: ${charge.failureCode}`
              this.isLoading = false
              return
            }

            this.assistedPayStore.isPaymentComplete = charge.paid

            this.customerToPayModel.paymentIntentId = ''
            this.customerToPayModel.cardBrand = this.assistedPayStore.brand
            this.customerToPayModel.cardLastFourDigit = Number(this.assistedPayStore.last4)
            this.customerToPayModel.chargeId = charge.id
            this.customerToPayModel.balanceTransactionId = ''
            this.customerToPayModel.isTransactionCompleted = charge.paid
          }
        } else {
          // Create a payment method to associate with the Payment Intent we'll create later to capture the payment
          const paymentMethod = await this.createPaymentMethod(this.customerToPayModel.cardHolderName, this.card)

          const paymentIntent = await PaymentController.CreatePaymentIntent(
            this.jobId,
            this.amount,
            this.customerToPayModel.billingAddress.postcode,
            this.customerToPayModel.cardHolderName,
            this.customerToPayModel.cardHolderEmail,
            paymentMethod.card.brand,
            paymentMethod.id,
            this.customerToPayModel.statementDescription,
            this.customerToPayModel.paymentType
          )

          // If we've reached this point (i.e., payment intent created successfully) then the payment has been captured.

          this.customerToPayModel.paymentIntentId = paymentIntent.id
          this.customerToPayModel.cardBrand = paymentMethod.card.brand
          this.customerToPayModel.cardLastFourDigit = paymentMethod.card.last4
          this.customerToPayModel.chargeId = R.pathOr(null, ['charges', 'data', 0, 'id'], paymentIntent)
          this.customerToPayModel.balanceTransactionId = R.pathOr(
            null,
            ['charges', 'data', 0, 'balance_transaction'],
            paymentIntent
          )

          this.customerToPayModel.isTransactionCompleted = true
        }

        this.customerToPayModel.transactionDate = moment.utc(new Date())

        // Create the CustomerToPay record
        const custPay: AddCustomerToPay = new AddCustomerToPay()
        custPay.jobId = this.jobId
        custPay.paymentIntentId = this.customerToPayModel.paymentIntentId
        custPay.chargeId = this.customerToPayModel.chargeId
        custPay.createdAt = moment.utc(new Date())
        custPay.cardHolderName = this.customerToPayModel.cardHolderName
        custPay.cardHolderEmail = this.requiredReceipt ? this.customerToPayModel.cardHolderEmail : ''
        custPay.addressLine1 = this.customerToPayModel.billingAddress.line1
        custPay.addressLine2 = this.customerToPayModel.billingAddress.line2
        custPay.city = this.customerToPayModel.billingAddress.city
        custPay.state = this.customerToPayModel.billingAddress.state
        custPay.country = this.customerToPayModel.billingAddress.country
        custPay.postcode = this.customerToPayModel.billingAddress.postcode
        custPay.cardBrand = this.customerToPayModel.cardBrand
        custPay.cardLastFourDigit = this.customerToPayModel.cardLastFourDigit
        custPay.statementDescription = this.customerToPayModel.statementDescription
        custPay.amount = this.amount
        custPay.escalateCTPResponse = this.escalateCTPResponseModel ? this.escalateCTPResponseModel : null
        custPay.isTransactionCompleted = true
        custPay.transactionDate = this.customerToPayModel.transactionDate
        custPay.balanceTransactionId = this.customerToPayModel.balanceTransactionId
        custPay.paymentType = this.customerToPayModel.paymentType

        if (this.job) {
          if (this.job.emergencies.length === 1) {
            custPay.emergencyId = this.job.emergencies[0].id
          } else {
            const emergency: Emergency | undefined = this.job.emergencies.find(
              (e) => e.typeId === this.emergencyTypeId && e.detailId === this.emergencyDetailId
            )
            if (emergency) {
              custPay.emergencyId = emergency.id
            }
          }
        }

        // Add the CustomerToPay record to the database
        await PaymentController.AddCustomerToPayDetail(custPay)

        await this.resumeCallRecording()
      } catch (err) {
        eventBus.$emit('errorHandler', err)
        this.errorMessage = 'Failed to complete payment - please contact support'
      }

      this.isLoading = false
    }
  }

  private convertPoundsToPence(amount: number) {
    return amount * 100
  }

  private validationMessage(label: string) {
    const message: string = this.$validator.errors.collect(label)[0]
    return message === 'The Statement Description field format is invalid.'
      ? 'Special Characters are not allowed.'
      : message
  }

  private async validate(): Promise<boolean> {
    if (this.amount < this.escalatedCTPAmount) {
      this.errorMessage = 'Amount must not be less than ' + this.getFormatedCurrency(this.escalatedCTPAmount)
      return false
    }
    const result: boolean = await this.$validator.validateAll('frmCustomerToPayPreview')
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private async resumeCallRecording(): Promise<void> {
    if (this.currentCallSid) {
      try {
        const isPaused = await TwilioController.PauseRecording(this.currentCallSid, false)
        if (!isPaused) {
          eventBus.$emit('clearTwilioSnackbar')
        }
      } catch (err) {
        eventBus.$emit('showTwilioSnackbar', 'Error resuming recording for Customer to Pay process.', false)
      }
    }
  }

  private maxlengthValidation(event: any, value: number, maxlength: number) {
    if (Shared.IsMaxLength(value, maxlength)) {
      event.preventDefault()
      return
    }
  }

  private onEmergencyTypeChange(emergencyTypeId: any) {
    this.emergencyDetailId = null
    if (this.job) {
      const emergency: Emergency | undefined = this.job.emergencies.find((e) => e.typeId === emergencyTypeId)
      if (emergency) {
        this.customerToPayModel.statementDescription = emergency.typeDescription
      }
    }
  }

  @Watch('paymentType')
  private onPaymentTypeChange() {
    this.customerToPayModel.paymentType = PaymentType[this.paymentType]
  }

  private getFormatedCurrency(value: number) {
    return Shared.getFormatedCurrency(value)
  }

  private onRequiredReceiptOptionChange(requiredReceipt: boolean) {
    if (!requiredReceipt) {
      this.customerToPayModel.cardHolderEmail = ''
    }
  }

  public agentAssistedProgressChanged(inProgress: boolean) {
    this.assistedPaymentInProgress = inProgress
  }

  public clearErrorMessage() {
    this.errorMessage = ''
  }
}
</script>

<style scoped>
.customer-payment .payment-form {
  display: inline-block;
  width: 100%;
}
form {
  width: 480px;
  margin: 20px 0;
}
.group {
  margin-bottom: 15px;
}
.agent-note {
  background-color: #fff2af;
}
label {
  position: relative;
  color: #8898aa;
  font-weight: 300;
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
}
.field {
  transition: all 0.3s linear;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 31px;
  margin: 0;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  border-bottom: 1px solid #949494;
}
.field:focus,
.customer-payment >>> .StripeElement--focus,
.field:hover {
  outline: 0;
  border-color: #000;
}
.field::-webkit-input-placeholder {
  color: #cfd7e0;
}
.field::-moz-placeholder {
  color: #cfd7e0;
}
button:focus {
  background: #555abf;
}
button:active {
  background: #43458b;
}
.outcome {
  float: left;
  width: 100%;
  padding-top: 8px;
  min-height: 24px;
  text-align: center;
}
.success-result,
.error {
  display: none;
  font-size: 13px;
}
.success-result.visible,
.error.visible {
  display: inline;
}
.error {
  color: #e4584c;
}
.success-result {
  color: #666ee8;
}
.success-result .token {
  font-weight: 500;
  font-size: 13px;
}
.input-group.input-fc label {
  position: absolute;
  top: 10px;
  left: -4px;
}
.input-group.input-fc {
  padding-top: 18px;
  position: relative;
}
.input-group.input-fc > div {
  display: inline-block;
  width: 100%;
  position: relative;
}
.input--focus + label {
  -webkit-transform: translateY(-13px) scale(0.75);
  transform: translateY(-13px) scale(0.75);
  color: rgba(0, 0, 0, 0.54);
}
.StripeElement:after {
  content: '';
  -webkit-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #009688;
  color: inherit;
  position: absolute;
  bottom: -2px;
  height: 2px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: center center 0;
  transform-origin: center center 0;
  width: 100%;
  z-index: 1;
}
.StripeElement.StripeElement--focus:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.card-type-img {
  position: absolute;
  right: 0;
  max-width: 100px;
}
.StripeElement.StripeElement--focus + label {
  color: #009688;
}

.error-info {
  margin-top: 12px;
}
.payment-card-disabled {
  pointer-events: none;
}

.amount-input >>> .v-input {
  padding-top: 0px;
  padding-left: 20px;
  margin: 0;
}
.amount-input {
  position: relative;
}
.amount-input .pound-icon {
  position: absolute;
  top: 5px;
  left: 5px;
}
.prepenpound-icon >>> .v-input .v-icon {
  color: #ffffff !important;
}
.prepenpound-icon {
  position: relative;
}
.pound-icon {
  top: 18px;
  position: ABSOLUTE;
  left: 6px;
  z-index: 1;
}
.call-sid-text {
  font-size: 16px;
}

@media (max-width: 1279px) {
  .title {
    display: inline-block;
  }
}
</style>
