var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.user
    ? _c(
        "div",
        [
          _c(
            "v-layout",
            {
              staticClass: "profile-banner pt-3",
              attrs: { "align-center": "", "flex-col": "" },
            },
            [
              _c("ProfileImage", {
                attrs: { image: _vm.user.profileImage },
                on: { "update:image": _vm.onProfilePictureChanged },
              }),
            ],
            1
          ),
          _c(
            "v-container",
            { attrs: { "grid-list-md": "" } },
            [
              _c(
                "v-layout",
                { staticClass: "user-details", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          name: "firstName",
                          label: "First Name",
                          "error-messages": _vm.errors.first("firstName"),
                          disabled: !_vm.relativePermissions.canChangeAccount,
                        },
                        model: {
                          value: _vm.user.firstName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "firstName", $$v)
                          },
                          expression: "user.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          name: "lastName",
                          label: "Last Name",
                          "error-messages": _vm.errors.first("lastName"),
                          disabled: !_vm.relativePermissions.canChangeAccount,
                        },
                        model: {
                          value: _vm.user.lastName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "lastName", $$v)
                          },
                          expression: "user.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email|unique",
                            expression: "'required|email|unique'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          name: "email",
                          type: "email",
                          label: "Email",
                          "error-messages": _vm.errors.first("email"),
                          disabled: !_vm.relativePermissions.canChangeAccount,
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          name: "role",
                          label: "Role",
                          items: _vm.roles,
                          loading: _vm.loadingRoles,
                          "item-value": "id",
                          "item-text": "name",
                          disabled: !_vm.canChangeRole,
                        },
                        model: {
                          value: _vm.user.role,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "role", $$v)
                          },
                          expression: "user.role",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.showTeamField
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "newindicator-chip" },
                          [
                            _c("v-combobox", {
                              attrs: {
                                name: "Team",
                                label: "Team",
                                maxlength: "50",
                                items: _vm.teams,
                                loading: _vm.loadingTeams,
                                "item-value": "id",
                                "item-text": "name",
                                "return-object": false,
                                disabled:
                                  !_vm.relativePermissions.canChangeTeam,
                              },
                              model: {
                                value: _vm.user.team,
                                callback: function ($$v) {
                                  _vm.$set(_vm.user, "team", $$v)
                                },
                                expression: "user.team",
                              },
                            }),
                            _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: "secondary",
                                  small: "",
                                  disabled: "",
                                  value: _vm.isNewTeam,
                                },
                              },
                              [_vm._v("New")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "job-types", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [_c("v-label", [_vm._v("Job Types")])],
                    1
                  ),
                  _vm._l(_vm.jobTypes, function (jobType) {
                    return _c(
                      "v-flex",
                      { key: jobType.id, attrs: { sm4: "" } },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            value: jobType.id,
                            label: jobType.name,
                            color: "primary",
                            disabled:
                              !_vm.relativePermissions.canChangeJobTypes,
                          },
                          model: {
                            value: _vm.user.jobTypes,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "jobTypes", $$v)
                            },
                            expression: "user.jobTypes",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-layout",
                { staticClass: "switches", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "primary",
                          small: "",
                          label: "Can Accept/Reject Emergencies",
                        },
                        model: {
                          value: _vm.user.canAcceptEmergencies,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "canAcceptEmergencies", $$v)
                          },
                          expression: "user.canAcceptEmergencies",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "primary",
                          small: "",
                          label: "Can Action Customer To Pay",
                        },
                        model: {
                          value: _vm.user.canAcceptPayments,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "canAcceptPayments", $$v)
                          },
                          expression: "user.canAcceptPayments",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "primary",
                          small: "",
                          label: "Works For Third Party",
                        },
                        model: {
                          value: _vm.user.worksForThirdParty,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "worksForThirdParty", $$v)
                          },
                          expression: "user.worksForThirdParty",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "dashboards", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [_c("v-label", [_vm._v("Dashboards")])],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: "Select All",
                          "hide-details": "",
                          name: "select-all-checkbox",
                          color: "primary",
                          disabled:
                            !_vm.relativePermissions.canChangeDashboards,
                        },
                        model: {
                          value: _vm.allDashboardsSelected,
                          callback: function ($$v) {
                            _vm.allDashboardsSelected = $$v
                          },
                          expression: "allDashboardsSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.dashboards, function (dashboard) {
                    return _c(
                      "v-flex",
                      {
                        key: dashboard.id,
                        staticClass: "dashboards__dashboard",
                        attrs: { sm4: "" },
                      },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            value: dashboard.id,
                            label: dashboard.name,
                            disabled:
                              !_vm.relativePermissions.canChangeDashboards,
                            color: "primary",
                          },
                          model: {
                            value: _vm.user.dashboards,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "dashboards", $$v)
                            },
                            expression: "user.dashboards",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Default Dashboard",
                          "item-text": "name",
                          "item-value": "id",
                          items: _vm.dashboards,
                          disabled:
                            !_vm.relativePermissions.canChangeDashboards,
                        },
                        model: {
                          value: _vm.user.defaultDashboard,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "defaultDashboard", $$v)
                          },
                          expression: "user.defaultDashboard",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "v-layout",
                { staticClass: "skills", attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [_c("v-label", [_vm._v("Skill Matrix")])],
                    1
                  ),
                  _vm._l(_vm.skills, function (skill) {
                    return _c(
                      "v-flex",
                      {
                        key: skill.key,
                        staticClass: "skills__skill",
                        attrs: { xs6: "" },
                      },
                      [
                        _c("label", { staticClass: "skills__label" }, [
                          _vm._v(_vm._s(skill.display)),
                        ]),
                        _c("star-rating", {
                          attrs: {
                            "star-size": 18,
                            "max-rating": 5,
                            "active-color": "#e7711b",
                            "show-rating": false,
                            clearable: true,
                            "read-only":
                              !_vm.relativePermissions.canChangeSkills,
                          },
                          model: {
                            value: _vm.user.skills[skill.key],
                            callback: function ($$v) {
                              _vm.$set(_vm.user.skills, skill.key, $$v)
                            },
                            expression: "user.skills[skill.key]",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }