import SkillModel from '@/models/user/SkillModel'
import DashboardUserRightsModel from './DashboardUserRightsModel'
import { Dashboard } from '@/models/types'
import moment from 'moment'
import { JobType } from '@/common/enums'

export type UserRole =
  | 'IncidentManager'
  | 'NetworkManager'
  | 'Supervisor'
  | 'Finance'
  | 'Administrator'
  | 'ClientManager'
  | 'SystemAdministrator'

export default class UserModel {
  public id: string
  public firstName: string
  public lastName: string
  public phone: string
  public email: string
  public roleName: UserRole
  public skills: SkillModel
  public teamName: string | null
  public skillLevel: number // the value to use for show skill level (avg. of user skills)
  public canAcceptRejectEmergency: boolean
  public dashboardUserRights: DashboardUserRightsModel[]
  public worksForThirdParty: boolean
  public hasCustomerToPayAccess: boolean
  public profileImageUrl: string | undefined
  public profileImageThumbnailUrl: string
  public twilioWorkerSid: string
  public timerStartedIM: moment.Moment | null
  public jobTypes: JobType[] // add the job Type option to access the job
  public isActive: boolean
  public badgeNo: string

  public get profileImageUrlWithUnknownFallback() {
    return this.profileImageUrl ? this.profileImageUrl : '/img/unknownuser.png'
  }

  public get profileThumbnailUrlWithUnknownFallback() {
    return this.profileImageThumbnailUrl ? this.profileImageThumbnailUrl : '/img/unknownuser.png'
  }

  public get displayValue(): string {
    return `${this.firstName} ${this.lastName}`
  }

  public get defaultHomePage(): Dashboard | undefined {
    const dhp = (this.dashboardUserRights || []).find((d) => d.setAsDefault)
    if (dhp) {
      return dhp.name
    }
    return undefined
  }

  public constructor() {
    this.skills = new SkillModel()
    this.dashboardUserRights = []
    this.isActive = true
  }
}
