<template>
  <div>
    <div v-if="isLoading">
      <v-progress-circular
        :width="2"
        :size="50"
        indeterminate
        color="primary"
        :style="getLoaderStyle(70)"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="deployment">
        <v-flex class="mb-2">
          <AutoDeploymentDetail />
        </v-flex>
        <v-card
          v-if="isDeploymentUnsuccessful || !deployment.contractors?.length"
          color="red"
          class="pa-2 mb-2 text-center white--text"
          ><h5>Auto deployment hasn't been successful, advising manual deployment</h5>
        </v-card>
        <v-flex v-if="deployment.contractors?.length">
          <v-expansion-panel :value="accordionIndexToOpen" expand>
            <v-expansion-panel-content
              v-for="(contractor, index) in deployment.contractors"
              :key="`deployment-${index}`"
              :hide-actions="isJobStatusNotOffered(contractor.appointmentRequest)"
            >
              <template #header>
                <v-flex>
                  <v-layout justify-space-between align-center class="expansion-heading">
                    <v-layout column>
                      <h5 class="text-uppercase">{{ contractor.contractorName }}</h5>
                      <h6>{{ index + 1 }}. {{ contractor.strategy }}</h6>
                    </v-layout>
                    <v-chip
                      small
                      :color="
                        contractor.appointmentRequest
                          ? appointmentStatusMap[contractor.appointmentRequest.status].color
                          : nextToBeDeployed(deployment.contractors, index)
                          ? 'orange'
                          : 'grey'
                      "
                      text-color="white"
                    >
                      {{
                        contractor.appointmentRequest
                          ? appointmentStatusMap[contractor.appointmentRequest.status].text
                          : showTimer(deployment.contractors, index)
                          ? `Pending ${
                              timerCountdown.minutes ? timerCountdown.minutes + ' : ' + timerCountdown.seconds : ''
                            }`
                          : 'Not Offered'
                      }}</v-chip
                    >
                  </v-layout>
                </v-flex>
              </template>
              <AutoDeploymentContractorDetail
                v-if="
                  contractor.appointmentRequest &&
                  contractor.appointmentRequest.status !== appointmentStatusEnum.NotOffered
                "
                :contractor-appointed-id="contractor.contractorId"
                :deployment-id="deploymentId"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>
      </div>
      <div v-else>Deployment Currently Not Running</div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  AppointmentRequestStatus,
  AppointmentStatusMap,
  ContractorAppointment,
  ContractorDeployment,
  DeploymentStatus,
} from '@/models/autoDeployment/auto-deployment'
import { Component } from 'vue-property-decorator'
import { useDeploymentStore } from '@/pinia/deployment'
import AutoDeploymentDetail from './AutoDeploymentDetail.vue'
import AutoDeploymentContractorDetail from './AutoDeploymentContractorDetail.vue'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Shared from '@/common/shared'

@Component({
  components: {
    AutoDeploymentDetail,
    AutoDeploymentContractorDetail,
  },
})
export default class AutoDeploymentCardPreview extends TimeLineItemBase {
  public appointmentStatusEnum = AppointmentRequestStatus
  public appointmentStatusMap = AppointmentStatusMap
  public timerCountdown: { minutes: string; seconds: string } = {
    minutes: '',
    seconds: '',
  }
  public isLoading = true

  public async created() {
    if (!this.deploymentId) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('Failed to retrieve deploymentId, please contact support.', 0)
      )
      this.isLoading = false
      return
    }
    const deploymentStore = useDeploymentStore()
    await deploymentStore.retrieveAutoDeploymentDetail(this.jobId, this.deploymentId)
    this.isLoading = false
  }

  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public get deploymentId(): string | null {
    return this.card ? this.card.data['deploymentId'] : null
  }

  public get deployment() {
    return useDeploymentStore().autoDeployment
  }

  public get accordionIndexToOpen(): number {
    if (!this.deployment.contractors?.length) {
      return 0
    }
    const acceptedJobIndex = this.deployment.contractors.findIndex(
      (x) => x.appointmentRequest?.status === AppointmentRequestStatus.Accepted
    )
    // Always displayed the accepted job. If no job is accepted, displayed the most recently offered one
    return ~acceptedJobIndex ? acceptedJobIndex : this.deployment.contractors.length - 1
  }

  public isJobStatusNotOffered(appointment: ContractorAppointment | null): boolean {
    return appointment?.status === AppointmentRequestStatus.NotOffered
  }

  public getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  public get isDeploymentUnsuccessful(): boolean {
    return this.deployment.status === DeploymentStatus.Failed || this.deployment.status === DeploymentStatus.Expired
  }

  public showTimer(contractors: ContractorDeployment[], index: number): boolean {
    const isNextContractor = this.nextToBeDeployed(contractors, index)
    if (!isNextContractor) return false

    const previousContractor = contractors[index - 1]

    this.startCountdownTimer(new Date(previousContractor.appointmentRequest!.requestExpiration!))
    return true
  }

  public nextToBeDeployed(contractors: ContractorDeployment[], index: number) {
    if (index <= 0) return false

    const currentContractor = contractors[index]
    const previousContractor = contractors[index - 1]

    return (
      currentContractor.appointmentRequest === null &&
      previousContractor.appointmentRequest?.status === AppointmentRequestStatus.Offered
    )
  }

  private startCountdownTimer(requestExpiration: Date) {
    if (!this.timerCountdown.minutes) {
      const timer = setInterval(() => {
        const currentDate = new Date().getTime()
        const timeDifference = requestExpiration.getTime() - currentDate
        const padDigitsWithZero = (number: number) => (number < 10 ? '0' : '') + number
        this.timerCountdown!.minutes = padDigitsWithZero(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)))
        this.timerCountdown!.seconds = padDigitsWithZero(Math.floor((timeDifference % (1000 * 60)) / 1000))
        if (timeDifference <= 0) {
          clearInterval(timer)
          this.timerCountdown = {
            minutes: '',
            seconds: '',
          }
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.expansion-heading {
  line-height: 1.5;
}
</style>
