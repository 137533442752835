<template>
  <div style="position: relative; display: block">
    <v-progress-circular
      v-show="!isLoaded"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    />
    <div v-if="isLoaded && customerNotAvailableMessage && job && job.jobType === 'HE'">
      <v-card elevation-0 class="not-found-msg">
        <v-card-text class="subheading text-xs-center">
          {{ customerNotAvailableMessage }}
        </v-card-text>
      </v-card>
    </div>
    <template v-else>
      <div v-if="(contractorList.length > 0 || getThirdPartyContractor) && !loaderOnAutoDeploymentRunning">
        <v-flex xs12>
          <CallContractorDetailView
            ref="callContractorDetailView"
            :bind-from-prop="true"
            :item="isThirdPartyContractor ? getThirdPartyContractor : selectedItem"
            :emergency="emergency"
            :job-id="jobId"
            :job-latitude="job.latitude"
            :job-longitude="job.longitude"
            :assigned-contractors="getAssignedContractors"
            :is-third-party-contractor="isThirdPartyContractor"
            :is-contractor-selected-via-search-result="isContractorSelectedViaSearchResult"
            :is-un-assigned-contractor="true"
            @runManualAutoDeployment="runManualAutoDeployment"
          ></CallContractorDetailView>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-lg px-0>
            <v-layout wrap row>
              <template v-if="contractorList.length > 0 && !getThirdPartyContractor">
                <v-flex
                  v-for="(item, index) in contractorList"
                  :key="index"
                  xs12
                  sm6
                  xl4
                  class="callContractorTileView"
                  @click="clickContractorTileView(item, index)"
                >
                  <CallContractorTileView
                    :item="item"
                    :selected="activeTileIndex === index"
                    :assigned-contractors="getAssignedContractors"
                    :trade-id="emergency.tradeId"
                  ></CallContractorTileView>
                </v-flex>
              </template>
              <v-flex
                v-if="
                  (!isNearByContractorLoaded && !getThirdPartyContractor) ||
                  (isNearByContractorLoaded &&
                    !getThirdPartyContractor &&
                    (isNearBySecondaryContractorLoaded || isNearByTertiaryContractorLoaded))
                "
                xs12
                sm6
                xl4
              >
                <v-card class="nearby-contractor">
                  <v-btn
                    v-if="!isNearByContractorLoaded && !getThirdPartyContractor"
                    color="primary"
                    small
                    class="white--text btn-initial"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click="getNearByContractors(initialNearByDistance)"
                  >
                    <v-icon small>place</v-icon>
                    &nbsp;Near By Contractors
                    <span>&nbsp;({{ initialNearByDistance }}{{ distanceMeasurement }})</span>
                  </v-btn>

                  <v-btn
                    v-if="isNearByContractorLoaded && !getThirdPartyContractor && isNearBySecondaryContractorLoaded"
                    color="primary"
                    small
                    class="white--text btn-secondary"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click="getNearByContractors(secondaryNearByDistance)"
                  >
                    <v-icon small>place</v-icon>
                    &nbsp;Near By Contractors
                    <span>&nbsp;({{ secondaryNearByDistance }}{{ distanceMeasurement }})</span>
                  </v-btn>

                  <v-btn
                    v-if="
                      isNearByContractorLoaded &&
                      !getThirdPartyContractor &&
                      !isNearBySecondaryContractorLoaded &&
                      isNearByTertiaryContractorLoaded
                    "
                    color="primary"
                    small
                    class="white--text btn-tertiary"
                    :disabled="isLoading"
                    :loading="isLoading"
                    @click="getNearByContractors(tertiaryNearByDistance)"
                  >
                    <v-icon small>place</v-icon>
                    &nbsp;Near By Contractors
                    <span>&nbsp;({{ tertiaryNearByDistance }}{{ distanceMeasurement }})</span>
                  </v-btn>
                </v-card>
              </v-flex>
              <v-flex
                v-if="
                  isNearByContractorLoaded &&
                  (!isNearByTertiaryContractorLoaded || !getIsSkillLevelAboveRequired) &&
                  !isNearBySecondaryContractorLoaded
                "
                xs12
                sm6
                xl4
              >
                <v-card class="nearby-contractor">
                  <div class="extra-contractor-buttons">
                    <v-btn color="primary" small class="white--text btn-add" @click="addContractorClick()">
                      <v-icon small>person_add</v-icon>
                      &nbsp;Contractor
                    </v-btn>
                  </div>
                  <div class="extra-contractor-buttons">
                    <v-btn
                      v-if="getIsSkillLevelAboveRequired"
                      color="primary"
                      small
                      class="white--text btn-search"
                      @click="searchButtonClicked"
                    >
                      <v-icon small>search</v-icon>
                      &nbsp;Contractor
                    </v-btn>
                  </div>
                </v-card>
              </v-flex>
              <v-flex v-if="showCustomerAppointedTradespeopleBtn" xs12 sm6 xl4>
                <v-card class="nearby-contractor">
                  <v-btn
                    color="primary"
                    small
                    class="white--text customer-appointed-tradespeople"
                    :disabled="isAssignCATLoading"
                    :loading="isAssignCATLoading"
                    @click="addCustomerAppointedTradesPeople"
                  >
                    Customer Appointed Tradespeople
                  </v-btn>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <CallContractorSearchView
                  ref="callContractorSearchView"
                  :job-id="jobId"
                  :emergency-id="emergencyId"
                  :contractor-search-list="contractorSearchList"
                  :enable-search-contractor="enableSearchContractor"
                  :active-search-tile-index="activeSearchTileIndex"
                  :third-party-contractor="thirdPartyContractor"
                  :is-third-party-contractor="isThirdPartyContractor"
                  :emergency="emergency"
                  @onSearchInputChanged="onSearchInputChanged"
                  @clickContractorTileView="clickContractorTileView"
                ></CallContractorSearchView>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </div>
      <div
        v-show="!getContractorAvailability && isLoaded && !getThirdPartyContractor && !loaderOnAutoDeploymentRunning"
      >
        <v-flex xs12>
          <v-card elevation-0 class="not-found-msg">
            <v-card-text v-if="!isContractorSelected" class="subheading text-xs-center">
              No contractor found for this emergency...
            </v-card-text>
            <v-flex v-if="isContractorSelected" xs12 mb-4>
              <CallContractorDetailView
                ref="callContractorDetailView"
                :bind-from-prop="true"
                :item="isThirdPartyContractor ? getThirdPartyContractor : selectedItem"
                :emergency="emergency"
                :job-id="jobId"
                :job-latitude="job.latitude"
                :job-longitude="job.longitude"
                :assigned-contractors="getAssignedContractors"
                :is-third-party-contractor="isThirdPartyContractor"
                :is-contractor-selected-via-search-result="isContractorSelectedViaSearchResult"
                :is-un-assigned-contractor="true"
                @runManualAutoDeployment="runManualAutoDeployment"
              ></CallContractorDetailView>
            </v-flex>
            <v-card-actions
              v-if="
                !isNearByContractorLoaded ||
                (isNearByContractorLoaded && (isNearBySecondaryContractorLoaded || isNearByTertiaryContractorLoaded))
              "
              class="pb-4"
            >
              <div class="text-xs-center full-width near-by-contractor">
                <v-btn
                  v-if="!isNearByContractorLoaded"
                  color="teal"
                  small
                  class="mr-0 white--text"
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="getNearByContractors(initialNearByDistance)"
                >
                  <v-icon small>place</v-icon>
                  &nbsp;Near By Contractors
                  <span>&nbsp;({{ initialNearByDistance }}{{ distanceMeasurement }})</span>
                </v-btn>
                <v-btn
                  v-if="isNearByContractorLoaded && isNearBySecondaryContractorLoaded"
                  color="teal"
                  small
                  class="mr-0 white--text"
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="getNearByContractors(secondaryNearByDistance)"
                >
                  <v-icon small>place</v-icon>
                  &nbsp;Near By Contractors
                  <span>&nbsp;({{ secondaryNearByDistance }}{{ distanceMeasurement }})</span>
                </v-btn>
                <v-btn
                  v-if="
                    isNearByContractorLoaded && !isNearBySecondaryContractorLoaded && isNearByTertiaryContractorLoaded
                  "
                  color="teal"
                  small
                  class="mr-0 white--text"
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="getNearByContractors(tertiaryNearByDistance)"
                >
                  <v-icon small>place</v-icon>
                  &nbsp;Near By Contractors
                  <span>&nbsp;({{ tertiaryNearByDistance }}{{ distanceMeasurement }})</span>
                </v-btn>
                <v-btn
                  v-if="showCustomerAppointedTradespeopleBtn"
                  color="primary"
                  small
                  class="mr-0 white--text"
                  :disabled="isAssignCATLoading"
                  :loading="isAssignCATLoading"
                  @click="addCustomerAppointedTradesPeople"
                >
                  Customer Appointed Tradespeople
                </v-btn>
              </div>
            </v-card-actions>

            <div
              v-if="
                isNearByContractorLoaded &&
                (!isNearByTertiaryContractorLoaded || !getIsSkillLevelAboveRequired) &&
                !isNearBySecondaryContractorLoaded
              "
              class="pb-3"
            >
              <v-layout>
                <v-flex class="text-xs-right">
                  <v-btn color="primary" small class="white--text" @click="addContractorClick()">
                    <v-icon small>person_add</v-icon>
                    &nbsp;Contractor
                  </v-btn>
                </v-flex>
                <v-flex>
                  <v-btn
                    v-if="getIsSkillLevelAboveRequired"
                    color="primary"
                    small
                    class="white--text"
                    @click="searchButtonClicked"
                  >
                    <v-icon small>search</v-icon>
                    &nbsp;Contractor
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex px-3>
                  <CallContractorSearchView
                    :job-id="jobId"
                    :emergency-id="emergencyId"
                    :contractor-search-list="contractorSearchList"
                    :enable-search-contractor="enableSearchContractor"
                    :active-search-tile-index="activeSearchTileIndex"
                    :is-third-party-contractor="isThirdPartyContractor"
                    :third-party-contractor="thirdPartyContractor"
                    :emergency="emergency"
                    @onSearchInputChanged="onSearchInputChanged"
                    @clickContractorTileView="clickContractorTileView"
                  ></CallContractorSearchView>
                </v-flex>
              </v-layout>
            </div>
          </v-card>
        </v-flex>
      </div>
      <div v-if="loaderOnAutoDeploymentRunning" class="engineer-deploy-loader white elevation-1">
        <v-progress-circular
          :width="2"
          :size="50"
          indeterminate
          color="primary"
          :style="getLoaderStyle(70)"
        ></v-progress-circular>
        <span class="message-loader">
          <b>Auto deployment is running, please wait..!</b>
        </span>
      </div>
    </template>
    <v-dialog
      v-if="showContractorProfileDialog"
      v-model="showContractorProfileDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <div v-bar="{ useScrollbarPseudo: true }">
        <div>
          <ContractorProfile
            ref="contractorProfile"
            :contractor-detail="contractorProfileData"
            :is-from-call-contractor="true"
            :show-contractor-profile-in-dialog="true"
            @saveContractor="addContractor"
            @onCancelChanges="showContractorProfileDialog = false"
          ></ContractorProfile>
        </div>
      </div>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CallContractorDetailView from '@/components/timeline/CallContractorDetailView.vue'
import CallContractorTileView from '@/components/timeline/CallContractorTileView.vue'
import CallContractorSearchView from '@/components/timeline/CallContractorSearchView.vue'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorController from '@/api/contractorController'
import Emergency from '@/models/Emergency'
import storeGetters from '@/storeGetters'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { JobVisitStatus, AutoDeploymentTypeEnum } from '@/common/enums'
import ContractorProfile from '@/views/ContractorProfile.vue'
import store from '@/store'
import JobContractors from '../../models/contractor/JobContractorsModel'
import AutoDeployRequest from '../../models/requests/AutoDeployRequestModel'
import JobController from '../../api/jobController'
import eventBus from '../../common/bus'
import moment from 'moment'
import AddCustomerAppointedTradespeopleRequest from '@/models/requests/AddCustomerAppointedTradespeopleRequest'
import Shared from '@/common/shared'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'

@Component({
  components: {
    CallContractorDetailView,
    CallContractorTileView,
    ContractorProfile,
    CallContractorSearchView,
  },
})
export default class CallContractorPreview extends TimeLineItemBase {
  private contractors: ContractorModel[] = []
  private thirdPartyContractor: ContractorModel | null = null
  private oldEmergency: Emergency | null = null
  private selectedItem: ContractorModel | null = null
  private activeTileIndex = -1
  private activeSearchTileIndex = -1
  private isComponentLoaded = false
  private isContractorAvailable = false
  private isLoading = false
  private isNearByContractorLoaded = false
  private isThirdPartyContractor = false
  public contractorProfileData: ContractorModel | null = null
  private showContractorProfileDialog = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private isNearBySecondaryContractorLoaded = false
  private isNearByTertiaryContractorLoaded = false
  private enableSearchContractor = false
  private skillNeededToSearch = 4

  private distanceMeasurement = 'km'
  private initialNearByDistance = 25
  private secondaryNearByDistance = 50
  private tertiaryNearByDistance = 100
  private contractorSearch = ''
  private contractorSearchList: ContractorModel[] = []
  private loaderOnAutoDeploymentRunning = false
  private isContractorSelected = false
  private isContractorSelectedViaSearchResult = false
  private isAssignCATLoading = false
  private customerNotAvailableMessage = ''

  private get isLoaded(): boolean {
    return this.isComponentLoaded
  }

  private get getContractorAvailability(): boolean {
    return this.isContractorAvailable
  }

  private get getAssignedContractors(): ContractorAppointedModel[] {
    return storeGetters.getContractorAppointedDetail(this.jobId, this.emergencyId).sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1
      }
      return 0
    })
  }
  private get getIsSkillLevelAboveRequired(): boolean {
    return store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment >= this.skillNeededToSearch
  }
  private get getAssignedContractor(): ContractorAppointedModel | undefined {
    return this.getAssignedContractors.find((c) => c.contractorId === (this.selectedItem ? this.selectedItem.id : null))
  }

  private get contractorList(): ContractorModel[] {
    if (this.oldEmergency === null || this.oldEmergency !== this.emergency) {
      this.getJobContractors()
      this.oldEmergency = this.emergency
    }
    return this.contractors
  }

  private get isLegacyAutoDeploymentEnabled() {
    return !this.$ld.variation('fnol-401-multiple-contractor-notifications')
  }

  private onSearchInputChanged(newValue: any) {
    if (newValue !== '' && newValue.length >= 3) {
      ContractorController.GetContractorBySimilarName(newValue).then((res: ContractorModel[] | null) => {
        this.contractorSearchList = res!
      })
    } else {
      this.contractorSearchList = []
    }
    this.scrollToEnd()
  }
  private async scrollToEnd() {
    if (document.getElementsByClassName('contentShadow') && document.getElementsByClassName('contentShadow')[0]) {
      // scroll to top for any tile view clicked
      const containerForCallContractorPreview: any = document.getElementsByClassName('contentShadow')[0].firstChild
      // allows time for the element to add extra height from the search box
      setTimeout(() => {
        containerForCallContractorPreview.scrollTop = containerForCallContractorPreview.scrollHeight
      }, 150)
    }
  }
  private created() {
    if (
      this.emergency !== undefined &&
      this.emergency !== null &&
      this.emergency.thirdPartyContractor !== '' &&
      this.emergency.thirdPartyContractor !== null &&
      this.emergency.thirdPartyContractor !== undefined
    ) {
      const id: string = this.emergency.thirdPartyContractor
      this.getContractorDetails(id)
    }
  }

  private get getThirdPartyContractor() {
    this.isThirdPartyContractor =
      this.emergency !== undefined &&
      this.emergency !== null &&
      this.emergency.thirdPartyContractor !== '' &&
      this.emergency.thirdPartyContractor !== null &&
      this.emergency.thirdPartyContractor !== undefined
        ? true
        : false
    return this.thirdPartyContractor
  }
  private searchButtonClicked() {
    this.contractorSearchList = []
    this.enableSearchContractor = !this.enableSearchContractor

    // scroll to the bottom
    this.scrollToEnd()
  }
  private getContractorDetails(contractorId: string) {
    ContractorController.GetJobContractorDetails(contractorId)
      .then((res: ContractorModel | null) => {
        if (res) {
          this.thirdPartyContractor = res
          this.isComponentLoaded = true
        } else {
          this.thirdPartyContractor = new ContractorModel()
          this.isComponentLoaded = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor job detail, please try again', true)
      })
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private async getJobContractors() {
    this.customerNotAvailableMessage = ''
    // if job status is 'Cancelled' or Cancellation reason is added, API should not be called
    if (this.job!.status === 'Cancelled' || this.job!.cancellationReason) {
      return
    }
    if (!this.emergency?.tradeId || !this.job?.postCode) {
      this.contractors = []
      this.selectedItem = null
      this.isComponentLoaded = true
      this.isContractorAvailable = false
      return
    }

    try {
      let res: JobContractors | null = null
      if (this.isLegacyAutoDeploymentEnabled) {
        res = await this.retrieveContractorJobsLegacy()
      } else {
        res = await this.retrieveContractorJobs()
      }
      this.onJobContractorsSuccess(res)
    } catch {
      this.onJobContractorsError()
    }
  }

  private async retrieveContractorJobsLegacy(): Promise<JobContractors | null> {
    return await ContractorController.GetJobContractors(
      this.emergency!.tradeId,
      this.job!.latitude,
      this.job!.longitude,
      this.job!.postCode.split(' ')[0] + ' ',
      this.jobId,
      this.emergencyId
    )
  }

  private async retrieveContractorJobs(): Promise<JobContractors | null> {
    return await ContractorController.GetContractorsForJob(this.jobId, {
      tradeId: this.emergency!.tradeId,
      postcode: this.job!.postCode.split(' ')[0] + ' ',
      emergencyId: this.emergencyId,
      latitude: this.job!.latitude,
      longitude: this.job!.longitude,
    })
  }

  private onJobContractorsSuccess(res: JobContractors | null) {
    if (!res) {
      this.customerNotAvailableMessage = 'Customer availability not set'
      this.isComponentLoaded = true
      return
    }

    this.loaderOnAutoDeploymentRunning = res.autoDeploymentStarted

    const isContractorsAvailable = res.contractors.length > 0 && !this.loaderOnAutoDeploymentRunning

    this.contractors = isContractorsAvailable ? res.contractors : []
    this.selectedItem = isContractorsAvailable ? this.contractors[0] : null
    this.activeTileIndex = isContractorsAvailable ? 0 : -1
    this.isContractorAvailable = isContractorsAvailable

    this.isComponentLoaded = true
  }

  private onJobContractorsError() {
    this.$store.dispatch(
      'snackbarModule/showSnackbar',
      new ShowErrorSnackbar('Error loading contractor(s), please try again')
    )
    this.isComponentLoaded = true
    this.isContractorAvailable = false
    this.customerNotAvailableMessage = 'Customer availability not set'
  }

  private clickContractorTileView(
    item: ContractorModel,
    index: number,
    isSearch: boolean,
    viaContractorSearchResult = false
  ) {
    if (isSearch) {
      this.activeTileIndex = -1
      this.activeSearchTileIndex = index
    } else {
      this.activeTileIndex = index
      this.activeSearchTileIndex = -1
    }
    this.isContractorSelected = true
    this.selectedItem = item

    // scroll to top for any tile view clicked
    if (document.getElementsByClassName('contentShadow') && document.getElementsByClassName('contentShadow')[0]) {
      const containerForCallContractorPreview: any = document.getElementsByClassName('contentShadow')[0].firstChild
      containerForCallContractorPreview.scrollTop = 0
    }

    if (viaContractorSearchResult) {
      this.isContractorSelectedViaSearchResult = true
    }
  }

  private getNearByContractors(nearByDistance: number) {
    if (this.emergency && this.emergency.tradeId && this.job && this.job.postCode) {
      this.isLoading = true
      // multiply with 1000 to get distance in KM
      const distance: number = nearByDistance * 1000
      ContractorController.GetNearByContractors(
        this.emergency.tradeId,
        this.job.latitude,
        this.job.longitude,
        distance.toString()
      )
        .then((res: ContractorModel[]) => {
          if (res && res.length > 0) {
            res.forEach((e: ContractorModel) => {
              if (this.contractors.findIndex((c: ContractorModel) => c.id === e.id) === -1) {
                e.isNearByContractor = true
                this.contractors.push(e)
              }
            })
            if (!this.isContractorAvailable) {
              this.isContractorAvailable = true
              this.selectedItem = this.contractors[0]
              this.activeTileIndex = 0
            }
          }

          this.isNearByContractorLoaded = true
          this.isNearBySecondaryContractorLoaded = true
          this.isNearByTertiaryContractorLoaded = true

          if (this.secondaryNearByDistance && nearByDistance >= this.secondaryNearByDistance) {
            this.isNearBySecondaryContractorLoaded = false
          }
          if (
            (this.tertiaryNearByDistance && nearByDistance >= this.tertiaryNearByDistance) ||
            !this.getIsSkillLevelAboveRequired
          ) {
            this.isNearByTertiaryContractorLoaded = false
          }

          this.isLoading = false
          this.scrollToEnd()
        })
        .catch((err: any) => {
          this.isLoading = false
          eventBus.$emit('errorHandler', 'Error loading near by contractor(s), please try again', true)
        })
    }
  }

  private addContractorClick() {
    // this.totalEngineer = 0;
    this.contractorProfileData = new ContractorModel()
    this.contractorProfileData.country = 'England'
    this.showContractorProfileDialog = true
  }

  private addContractor(isAdded, contractor) {
    if (isAdded && contractor !== null) {
      this.showContractorProfileDialog = false
      this.contractors.push(contractor)
      this.selectedItem = contractor
      this.activeTileIndex = this.contractors.length - 1
      this.isContractorAvailable = true
      this.snackbarText = 'Contractor added successfully.'
      this.snackbar = true
    }
  }

  private runManualAutoDeployment(
    jobId,
    emergencyId,
    contractorId,
    isFromSearchResult = false,
    isUnavailable = false,
    autoDeploymentType = ''
  ) {
    // call manual deployment
    const self = this
    const autoDeployEngineerRequest: AutoDeployRequest = new AutoDeployRequest()
    autoDeployEngineerRequest.jobId = jobId
    autoDeployEngineerRequest.emergencyId = emergencyId

    if (isFromSearchResult) {
      // if auto deploy started from manual search results
      const selectedContractor = self.contractorSearchList.find((x) => x.id === contractorId)
      if (selectedContractor && this.hasAutoDeploymentOn(selectedContractor)) {
        autoDeployEngineerRequest.contractorIds.push(selectedContractor.id)
      }
    } else {
      // if auto deploy started from auto search results
      // check auto deploy on next contractors
      if (!isUnavailable) {
        // if available
        const selectedContractorIndex = self.contractors.findIndex((x) => x.id === contractorId)
        if (selectedContractorIndex !== -1) {
          for (let i = selectedContractorIndex; i <= self.contractors.length - 1; i++) {
            if (this.hasAutoDeploymentOn(self.contractors[i])) {
              autoDeployEngineerRequest.contractorIds.push(self.contractors[i].id)
              // check availability of next contractor if it has auto deployment true
              const nextContractorId: number = selectedContractorIndex + 1
              const nextContractor = self.contractors[nextContractorId]
              if (nextContractor && this.hasAutoDeploymentOn(nextContractor)) {
                const nextContractorDetails: ContractorAppointedModel | undefined = self.getAssignedContractors.find(
                  (c) => c.contractorId === nextContractor.id
                )
                if (nextContractorDetails && nextContractorDetails.isUnavailable) {
                  // remove break and do i++ if want to continue autodeployment of next to next available contractor
                  // also check existence and unavailability of next to next contractor
                  break
                }
              }
            } else {
              break
            }
          }
        }
      } else {
        // if un-available
        // manually deployed by five start rating IM
        // auto deploy only selected contractor
        const selectedContractor = self.contractors.find((x) => x.id === contractorId)
        if (selectedContractor && this.hasAutoDeploymentOn(selectedContractor)) {
          autoDeployEngineerRequest.contractorIds.push(selectedContractor.id)
        }
      }
    }

    if (autoDeployEngineerRequest.contractorIds.length === 0) {
      self.snackbar = true
      self.snackbarText = 'No contractors found for auto deploy'
      // stop the loader
      const callContractorDetailViewSub = this.$refs.callContractorDetailView as CallContractorDetailView
      if (callContractorDetailViewSub !== undefined) {
        callContractorDetailViewSub.stopAutoDeploymentLoader()
      }
    }

    // call api to start auto deployment
    ContractorController.AutoDeployRequest(autoDeployEngineerRequest)
      .then((res: boolean) => {
        if (res) {
          // auto deployment automatically started as 1st contractor has auto deployment type
          this.loaderOnAutoDeploymentRunning = true
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error submitting Auto Deployment request, please try again', true)
      })
  }

  private hasAutoDeploymentOn(item: ContractorModel): boolean {
    // check is auto deployment set or not
    if (
      item.autoDeploymentType === undefined &&
      item.autoDeploymentType === AutoDeploymentTypeEnum[AutoDeploymentTypeEnum.NoAutodeployment]
    ) {
      return false
    }

    // if auto deployment set to 24 hours return true
    if (item.autoDeployAlwaysActive) {
      return true
    }

    // if manual time selected, check the auto deployment time to toggle between call contractor and auto deployment button
    const currentTime = moment(new Date())
    const beginningTime = item.autoDeployStartTime ? moment(item.autoDeployStartTime, 'h:mma') : null
    const endTime = item.autoDeployEndTime ? moment(item.autoDeployEndTime, 'h:mma') : null
    if (beginningTime && endTime) {
      if (beginningTime <= endTime) {
        if (beginningTime.isSameOrBefore(currentTime) && currentTime.isSameOrBefore(endTime)) {
          return true
        }
      } else {
        if (currentTime.isSameOrAfter(beginningTime) && currentTime.isSameOrAfter(endTime)) {
          return true
        }
        if (currentTime.isSameOrBefore(beginningTime) && currentTime.isSameOrBefore(endTime)) {
          return true
        }
      }
    }
    return false
  }

  private get showCustomerAppointedTradespeopleBtn(): boolean {
    return (
      this.job!.jobType === 'HE' &&
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.skills.deployment >= this.skillNeededToSearch)
    )
  }

  private addCustomerAppointedTradesPeople() {
    Shared.confirmationPopup.open(
      'Are you sure you want to mark emergency as Customer Appointed Tradespeople?',
      '',
      '',
      'No',
      'Yes',
      this,
      'assignCustomerAppointedTradesPeople',
      ''
    )
  }

  private assignCustomerAppointedTradesPeople() {
    this.isAssignCATLoading = true
    const customerAppointedTradespeopleRequest: AddCustomerAppointedTradespeopleRequest =
      new AddCustomerAppointedTradespeopleRequest()
    customerAppointedTradespeopleRequest.jobId = this.jobId
    customerAppointedTradespeopleRequest.emergencyId = this.emergencyId
    JobController.AddCustomerAppointedTradespeopleRequest(customerAppointedTradespeopleRequest)
      .then((res: boolean) => {
        if (!res) {
          this.snackbarText = 'Something went wrong!'
          this.snackbar = true
        }
        this.isAssignCATLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit(
          'errorHandler',
          'Error submiting assign customer appointed tradespeople request, please try again',
          true
        )
        this.isAssignCATLoading = false
      })
  }
}
</script>

<style scoped>
.avability-tabs >>> .v-tabs__div .v-tabs__item--active {
  background-color: #fff !important;
  font-weight: 600;
  border-top: 3px solid #009688;
  color: #009688;
}
.avability-tab-preview >>> .v-tabs__content,
.avability-tab-preview {
  min-height: 600px;
}
.call-contractor {
  min-height: 350px;
}
.extra-contractor-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.not-found-msg {
  border-bottom: 3px solid #009688;
}
.search-contractor {
  width: 100%;
  padding: 5% 5%;
}
.full-width {
  width: 100%;
}
.nearby-contractor {
  height: 100% !important;
  display: flex;
  min-height: 140px;
  flex-flow: row wrap;
}
.nearby-contractor >>> .v-btn {
  margin: auto;
}
.nearby-contractor >>> .v-btn__content {
  font-size: 12px;
}
.engineer-deploy-loader {
  display: flex;
  height: 600px;
}
.engineer-deploy-loader .message-loader {
  margin: auto;
  position: relative;
  top: 65px;
}
.near-by-contractor >>> .v-btn {
  margin-bottom: 15px;
  vertical-align: top;
}
.near-by-contractor >>> .v-btn:last-child {
  margin-bottom: 0px;
}
.nearby-contractor >>> .v-btn--small .v-btn__content {
  padding: 5px 0;
  -ms-flex-wrap: wrap;
  font-size: 11px;
  height: AUTO;
  word-break: break-all;
  white-space: inherit;
}
@media only screen and (max-width: 1300px) {
  .nearby-contractor .v-btn {
    height: auto;
    display: inline-block;
    width: 95%;
  }
  .nearby-contractor >>> .v-btn .v-btn__content {
    flex-wrap: wrap;
    padding: 5px 0px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .nearby-contractor >>> .v-btn--small .v-btn__content {
    padding: 5px 0px;
    flex-wrap: wrap;
    font-size: 11px;
  }
}
</style>
