import { JobType } from '@/common/enums'
import SkillModel from '../user/SkillModel'
import { UserRole } from '../user/UserModel'

export default class UserDetailModel {
  public id: string
  public firstName: string
  public lastName: string
  public email: string
  public role: UserRole = 'IncidentManager'
  public team: string | null = null
  public jobTypes: JobType[] = []
  public profileImage: string | null = null
  public profileImageThumbnail: string | null = null
  public averageSkill = 0
  public skills: SkillModel = new SkillModel()
  public canAcceptEmergencies = false
  public worksForThirdParty = false
  public canAcceptPayments = false
  public dashboards: string[] = []
  public defaultDashboard: string | null = null
  public isActive = true
}
