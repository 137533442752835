import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAssistedPayStore = defineStore('Assisted-Pay', () => {
  const paymentToken = ref('')
  const brand = ref('')
  const last4 = ref('')
  const isPaymentComplete = ref(false)

  function reset() {
    paymentToken.value = ''
    brand.value = ''
    last4.value = ''
    isPaymentComplete.value = false
  }

  function paymentDetailErrors(): {
    value: boolean
    message: string
  }[] {
    return [
      {
        value: Boolean(paymentToken.value),
        message: 'Payment token is missing',
      },
      {
        value: Boolean(brand.value),
        message: 'Payment brand is missing',
      },
      {
        value: last4.value.length === 4,
        message: 'Last 4 card digits are not set',
      },
    ].filter((x) => !x.value)
  }

  return {
    paymentToken,
    brand,
    last4,
    isPaymentComplete,
    reset,
    paymentDetailErrors,
  }
})
