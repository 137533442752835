<template>
  <v-layout v-if="Boolean(deployment.id)" row fill-height class="wrapper" pa-2>
    <v-flex xs6>
      <v-layout row wrap>
        <v-flex xs3 class="font-weight-bold mb-1">Status:</v-flex>
        <v-flex xs6>
          <v-chip
            small
            :color="deploymentStatusMap[deployment.status].color || 'blue'"
            text-color="white"
            class="mr-1"
            >{{ deploymentStatusMap[deployment.status].text || 'Unknown' }}</v-chip
          >
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs3 class="font-weight-bold mb-1 mr-1"> Postcode:</v-flex>
        <v-flex xs6>
          {{ deployment.postcode }}
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs3 class="font-weight-bold mb-1 mr-1">Trade:</v-flex>
        <v-flex xs6> {{ deployment.trade || 'Not Set' }} </v-flex>
      </v-layout>

      <v-layout v-if="isDelaysApplied" row wrap>
        <v-flex xs12 class="font-weight-bold mb-1">Delays</v-flex>
        <v-flex v-for="(delay, index) in filterDelays(deployment.tradeDelays)" :key="`${delay.delayCodeId}-${index}`">
          <v-flex xs12
            ><h5>{{ delay.description }}</h5></v-flex
          >

          <v-flex>
            <v-chip
              v-for="(delayRule, delayRuleIndex) in delay.delayRules"
              :key="`${delayRule.rule}-${delayRuleIndex}`"
              small
              color="red"
              text-color="white"
              class="mr-1 mb-1"
              >{{ delayRule.rule }}</v-chip
            >
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs6>
      <div class="pa-2">
        <InteractiveMap v-if="googleMapOptions" :map-options="googleMapOptions" />
        <h2 v-else>Unable to load map</h2>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { AutoDeployment, Delay, DeploymentStatusMap } from '@/models/autoDeployment/auto-deployment'
import { formatDateTime } from '@/common/datetime'
import InteractiveMap from '@/components/InteractiveMap.vue'
import { GoogleMapOptions } from '@/components/InteractiveMap.vue'
import { useDeploymentStore } from '@/pinia/deployment'

@Component({
  components: {
    InteractiveMap,
  },
})
export default class AutoDeploymentDetail extends Vue {
  public formatDateTime = formatDateTime
  public deploymentStatusMap = DeploymentStatusMap

  public get deployment(): AutoDeployment {
    return useDeploymentStore().autoDeployment
  }

  public get googleMapOptions(): GoogleMapOptions | null {
    if (!this.deployment?.postcode) return null

    const options: GoogleMapOptions = {
      mapId: 'auto-deployment-map-id',
      postcode: this.deployment.postcode,
    }
    return options
  }

  filterDelays(delays: Delay[]) {
    return delays
      .map((delay) => ({
        ...delay,
        delayRules: delay.delayRules.filter((rule) => rule.delayed),
      }))
      .filter((delay) => delay.delayRules.length > 0)
  }

  public get isDelaysApplied() {
    return this.deployment.tradeDelays.some((x) => x.delayRules.some((x) => x.delayed))
  }
}
</script>

<style scoped>
.v-chip--small {
  height: 12px !important;
  font-size: 12px;
  padding: 10px 0px;
  margin: 0;
}

.wrapper {
  background: white;
}
</style>
