<template>
  <v-flex xs12>
    <div v-if="job" class="bg-white pa-3">
      <v-layout row wrap>
        <v-flex xs12>
          <h3 v-if="job && job.isStarredPolicy">Logged as Assumed Cover</h3>
          <h3 v-if="job && !job.isStarredPolicy">Validated against Policy Holder Database</h3>
        </v-flex>
      </v-layout>
      <v-divider class="mt-2 mb-3"></v-divider>
      <v-layout row wrap>
        <v-flex lg5 xs12 pr-2>
          <div class="prperty-detail pro-address">
            <address v-if="job" class="px-3 pt-3 pb-3 grey lighten-4 address">
              <v-btn
                icon
                small
                class="change-ad-btn grey lighten-2"
                :disabled="!showUpdateCustomerDetailButton"
                @click="openChangeAddressPopup(true)"
              >
                <v-icon>edit</v-icon>
                <span v-if="job.addressModifiedCount > 0" class="edit-number">
                  {{ job.addressModifiedCount }}
                </span>
              </v-btn>
              <v-icon class="map-icon">place</v-icon>
              {{ job.clientFullName }},
              <div v-if="job.secondaryClientFullName">{{ job.secondaryClientFullName }},</div>
              {{ job.getAddress(', ') }}
            </address>
          </div>
          <div
            v-if="
              job.nomineeClientForename &&
              job.nomineeClientLastName &&
              job.nomineeContactPreference != '' &&
              (job.nomineeEmail || job.nomineeMobilePhone)
            "
            class="mb-4 mt-2"
          >
            <h3 class="mb-3">Nominee Information</h3>
            <v-layout wrap>
              <v-flex xs12>
                <div class="mb-1">
                  <b class="fb">Name:</b>
                  <span>{{ job.nomineeClientFullName }}</span>
                </div>
                <div v-if="job.relation" class="mb-1">
                  <b>Relation:</b>
                  <span>{{ getRelationWithNominee(job.relation) }}</span>
                </div>
                <div
                  v-if="job.nomineeContactPreference == 'Email' && job.nomineeEmail"
                  class="mb-1 primary--text prefrance-contact-icon"
                >
                  <b><v-icon class="md-icon" color="primary">mail</v-icon></b>
                  <a :href="'mailto:' + job.nomineeEmail">&nbsp; {{ job.nomineeEmail }}</a>
                </div>
                <div
                  v-if="
                    job.nomineeContactPreference == 'SMS' ||
                    (job.nomineeContactPreference == 'Call' && job.nomineeMobilePhone)
                  "
                  class="mb-1 primary--text prefrance-contact-icon"
                >
                  <b><v-icon class="md-icon" color="primary">call</v-icon></b>
                  <span>&nbsp; {{ job.nomineeMobilePhone }}</span>
                </div>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex lg7 xs12>
          <div class="prperty-detail">
            <Map
              v-if="job && job.latitude && job.longitude"
              :map-id="new Date().getTime().toString()"
              :source-latitude="job.latitude"
              :source-longitude="job.longitude"
              :show-street-view="true"
              style="height: 250px"
            ></Map>
          </div>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex v-if="job.policyEnquiry" xs12 class="mx-3 mb-3 mt-0">
          <div>
            <h4 class="mt-2">Category:</h4>
            <div>{{ job.policyEnquiry.category }}</div>
          </div>
          <div class="mt-3">
            <h4>Enquiry For:</h4>
            <div>
              <pre>{{ job.policyEnquiry.description }}</pre>
            </div>
          </div>
        </v-flex>
        <v-flex v-if="!job.helplinePolicy && !job.policyEnquiry && job.jobType !== 'SI'" xs12 mt-4 mb-2>
          <h3 class="mb-3 emergency-details-header">{{ jobTypeLabel('emergency') }} Details</h3>
          <div v-for="emergency in getEmergencyList" :key="emergency.id" class="fnol-emergency-details">
            <div>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    <i class="img-content">
                      <img v-if="emergency.fileURL !== null && emergency.fileURL !== ''" :src="emergency.fileURL" />
                      <img v-else src="/img/emergency.svg" alt />
                    </i>
                    <span class="em-title-content">
                      <b>{{ emergency.detailDescription + ': ' }}</b>
                    </span>
                  </span>
                </template>
                <span>
                  {{ emergency.typeDescription ? emergency.typeDescription : emergency.detailDescription }}
                </span>
              </v-tooltip>
              <span>{{ getFormattedDateTime(emergency.firstNoticedAt) }}</span>
              <div v-if="job.jobType === 'US' && emergency.note" class="mt-1 input-notes">
                <pre><b>Notes: </b>{{emergency.note}}</pre>
              </div>
              <v-tooltip v-if="showRemoveEmergencyButton" bottom>
                <template #activator="{ on }">
                  <v-icon
                    color="secondary"
                    dark
                    class="ml-2 em-delete"
                    v-on="on"
                    @click="openConfirmationDialogToRemoveEmergency(emergency.id)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Cancel Emergency</span>
              </v-tooltip>
            </div>
          </div>
        </v-flex>
        <v-divider v-if="job.removedEmergencies && job.removedEmergencies.length > 0" class="pa-1" />
        <v-flex v-if="job.removedEmergencies && job.removedEmergencies.length > 0" xs12 class="mb-2">
          <h3 class="mb-2 secondary--text">Cancelled Emergencies</h3>
          <div
            v-for="emergency in job.removedEmergencies"
            :key="emergency.emergencyId"
            class="grey lighten-4 pa-2 mb-1 remove-emergency-details"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <span v-on="on">
                  <i class="img-content">
                    <img v-if="getEmergencyFileURL(emergency.typeId)" :src="getEmergencyFileURL(emergency.typeId)" />
                    <img v-else src="/img/emergency.svg" alt />
                  </i>
                  <span class="em-title-content">
                    <b>{{ emergency.detailDescription }}</b>
                  </span>
                </span>
              </template>
              <span>{{ emergency.typeDescription }}</span>
            </v-tooltip>
            <v-divider class="mt-1" />
            <v-layout row wrap class="mt-1">
              <v-flex xs12>
                <span class="pr-2"><b>Reason:</b></span>
                <span class="mb-0">
                  {{ getRemoveEmergencyReason(emergency.reason) }}
                </span>
              </v-flex>
              <v-flex xs12>
                <span class="pr-2"><b>Description:</b></span>
                <span class="">{{ emergency.description }}</span>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
        <v-flex v-if="job.jobType === 'US' && job.linkedJobId" xs12 mb-2 mt-2>
          <span>
            <b>Linked Job:</b>
            {{ job.linkedJobId }}
          </span>
        </v-flex>
        <v-flex v-if="job.jobType === 'SI' && job.sIJobDetail" xs12 mb-2 mt-2>
          <div class="grey lighten-4 pa-2 mb-1 remove-emergency-details">
            <h3><b>Claim Management Company Details</b></h3>
            <v-divider class="mt-1 mb-1" />
            <div v-if="job.sIJobDetail.claimManagementCompany" class="mb-1">
              <b>Name:</b>
              <span>{{ job.sIJobDetail.claimManagementCompany }}</span>
            </div>
            <div v-if="job.sIJobDetail.claimManagementCompanyMainContact" class="mb-1">
              <b>Main Contact:</b>
              <span>
                {{ job.sIJobDetail.claimManagementCompanyMainContact }}
              </span>
            </div>
            <div v-if="job.sIJobDetail.claimManagementCompanyPhone" class="mb-1">
              <b>Phone:</b>
              <a
                href="javascript:void(0)"
                name="companyPhoneNumber"
                @click="onPhoneNumberClick(job.sIJobDetail.claimManagementCompanyPhone)"
              >
                {{ job.sIJobDetail.claimManagementCompanyPhone }}
              </a>
            </div>
            <div v-if="job.sIJobDetail.claimManagementCompanyEmail" class="mb-1">
              <b>Email:</b>
              <a :href="'mailto:' + job.sIJobDetail.claimManagementCompanyEmail">
                {{ job.sIJobDetail.claimManagementCompanyEmail }}
              </a>
            </div>
          </div>
        </v-flex>

        <v-flex
          v-if="(job.jobType === 'SI' && !siJobDelay && !job.isTestingOnlyJob) || (job.jobType === 'US' && !usJobDelay)"
          xs12
          class="text-xs-right"
        >
          <v-btn color="primary" class="btn-addDelay mr-0" @click="addJobDelayCard">Add Delay</v-btn>
          <v-divider></v-divider>
        </v-flex>

        <v-flex v-if="isUploadDocumentAllowed">
          <DocumentList :job-id="jobId" :job-type="job.jobType"></DocumentList>
        </v-flex>

        <v-flex
          v-if="job && canPlayCall && (job.fnolCallRecordingUrl || job.fnolCallSid)"
          xs12
          class="mb-2"
          :class="!job.helplinePolicy && !job.policyEnquiry ? 'mt-0' : 'mt-4'"
        >
          <v-divider></v-divider>
          <LinkedPhoneCallDetails
            :recording-url="job.fnolCallRecordingUrl"
            :call-sid="job.fnolCallSid"
            :job-id="job.jobId"
          ></LinkedPhoneCallDetails>
          <div class="client-detail text-xs-right">
            <em v-if="job && job.loggedByUserId && job.loggedByUserName">
              Logged by {{ job.loggedByUserName }} &nbsp;
              <v-avatar class="mr-2">
                <img :src="job.loggedByUser.profileThumbnailUrlWithUnknownFallback" alt="Logged user" />
              </v-avatar>
            </em>
            <em v-else-if="job && job.loggedVia">Logged via {{ job.loggedVia }}</em>
          </div>
        </v-flex>
      </v-layout>

      <v-dialog
        v-if="changeAddress"
        v-model="changeAddress"
        max-width="800"
        persistent
        content-class="v-dialog--scrollable"
        style="position: absolute"
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Update Client Information</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="openChangeAddressPopup(false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-3 scroll-content-dialog">
            <v-layout>
              <v-flex xs12>
                <CustomerAddress
                  ref="addCustomerAddress"
                  :passed-client-title.sync="getClientTitle"
                  :passed-first-name.sync="getFirstName"
                  :passed-last-name.sync="getLastName"
                  :passed-secondary-client-title.sync="getSecondaryClientTitle"
                  :passed-secondary-first-name.sync="getSecondaryFirstName"
                  :passed-secondary-last-name.sync="getSecondaryLastName"
                  :passed-address-line1.sync="getAddressLine1"
                  :passed-address-line2.sync="getAddressLine2"
                  :passed-address-line3.sync="getAddressLine3"
                  :passed-address-line4.sync="getAddressLine4"
                  :passed-post-code.sync="getPostCode"
                  :is-display-policy-number="false"
                  :is-s-i-job="job.jobType === 'SI'"
                ></CustomerAddress>
              </v-flex>
            </v-layout>
            <v-layout v-if="job.jobType === 'SI'">
              <v-flex xs6>
                <v-text-field
                  ref="clientReferenceRef"
                  v-model="customerRef"
                  v-validate="'required'"
                  label="Client Reference"
                  required
                  class="required"
                  name="ClientReference"
                  data-vv-name="Client Reference"
                  :error-messages="errors.collect('Client Reference')"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat="flat" class="close-btn" @click="openChangeAddressPopup(false)">Close</v-btn>
            <v-btn
              color="primary"
              class="mr-0 save-btn"
              :disabled="isLoading"
              :loading="isLoading"
              @click="saveAddress()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="removeEmergencyDialog"
        v-model="removeEmergencyDialog"
        max-width="650"
        content-class="v-dialog--scrollable"
        persistent
      >
        <v-card>
          <v-toolbar card dark color="primary">
            <v-toolbar-title>Remove Emergency</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="removeEmergencyDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider />
          <v-card-text class="scroll-content-dialog px-3">
            <v-form>
              <v-layout row wrap>
                <v-flex>
                  <v-select
                    v-model="removeEmergencyFromJobModel.reason"
                    v-validate="'required'"
                    :items="removeEmergencyReasonList"
                    item-text="description"
                    item-value="id"
                    label="What is the reason for removing this emergency?"
                    required
                    :loading="isLoading"
                    class="required"
                    data-vv-scope="frmRemoveEmergency"
                    data-vv-name="Emergency Remove Reason"
                    :error-messages="errors.collect('Emergency Remove Reason')"
                    @change="onRemoveEmergencyReasonChange"
                  >
                    <template slot="item" slot-scope="data">
                      <v-list-tile>
                        <v-list-tile-content>
                          <v-list-tile-title :class="data.item.description === 'Configure' ? 'bold-select' : ''">
                            {{ data.item.description }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="removeEmergencyFromJobModel.description"
                    v-validate="'required'"
                    label="Description"
                    required
                    class="required"
                    data-vv-scope="frmRemoveEmergency"
                    data-vv-name="Description"
                    :error-messages="errors.collect('Description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-3">
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="removeEmergencyDialog = false">Cancel</v-btn>
            <v-btn
              color="primary"
              class="mr-0 btn-removeEmergency"
              :loading="removeEmergencyLoading"
              :disabled="removeEmergencyLoading"
              @click="removeEmergency"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="configureDropdownDialog"
        v-model="configureDropdownDialog"
        max-width="700"
        persistent
        content-class="v-dialog--scrollable"
      >
        <ConfigureDropdown
          :record-type="configureDropdownType"
          @CloseConfigureDropdownDialog="onConfigureDropdownDialogClose"
        ></ConfigureDropdown>
      </v-dialog>
      <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
        {{ snackbarText }}
        <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
      </v-snackbar>
    </div>
  </v-flex>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import TimeLineItemBase from '@/components/TimeLineItemBase'
import Map from '@/components/Map.vue'
import moment from 'moment'
import Emergency from '@/models/Emergency'
import Shared from '@/common/shared'
import JobController from '@/api/jobController'
import Store from '@/store'
import storeGetters from '@/storeGetters'
import CustomerAddress from '@/components/CustomerAddress.vue'
import UpdateCustomerAddressDetail from '@/models/requests/UpdateCustomerAddressDetail'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import { RecordType } from '@/common/enums'
import storeMutations from '@/storeMutations'
import LinkedPhoneCallDetails from '@/components/timeline/LinkedPhoneCallDetails.vue'
import DocumentList from '@/components/DocumentList.vue'
import store from '@/store'
import RemoveEmergencyFromJobModel from '@/models/claim/RemoveEmergencyFromJobModel'
import ConfigureDropdown from '@/components/ConfigureDropdown.vue'
import eventBus from '@/common/bus'
import CallParameters from '@/models/twilio/CallParameters'
import UpdateCustomerRef from '@/models/requests/UpdateCustomerRef'

@Component({
  components: {
    Map,
    CustomerAddress,
    LinkedPhoneCallDetails,
    DocumentList,
    ConfigureDropdown,
  },
})
export default class FNOLPreview extends TimeLineItemBase {
  private emergencies: Emergency[] = []
  private changeAddress = false
  private isLoading = false
  private customerDetail: UpdateCustomerAddressDetail = new UpdateCustomerAddressDetail()
  private nomineeRelationList: MasterRecord[] = []
  private configureDropdownType = ''
  private removeEmergencyDialog = false
  private removeEmergencyReasonList: MasterRecord[] = []
  private configureDropdownDialog = false
  private removeEmergencyFromJobModel: RemoveEmergencyFromJobModel = new RemoveEmergencyFromJobModel()
  private removeEmergencyLoading = false
  private emergencyIdToRemove = ''
  private snackbar = false
  private snackbarTimeout = 5000
  private snackbarText = ''
  private allRemoveEmergencyReasonList: MasterRecord[] = []
  private updateCustomerRef: UpdateCustomerRef = new UpdateCustomerRef()

  private get getEmergencyList(): Emergency[] {
    this.emergencies = []
    if (this.job) {
      this.job.emergencies
        .filter((e) => e.displayInTimeline)
        .forEach((emergency) => {
          this.emergencies.push(emergency)
        })
    }
    return this.emergencies
  }

  private get getNomineeRelations() {
    const nomineeRelations: MasterRecord[] = storeGetters
      .getNomineeRelations()
      .filter((e) => e.isDeleted === false || e.isDeleted)
    return nomineeRelations
  }

  private getFormattedDateTime(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private get canPlayCall() {
    return Store.Instance.state.SessionDetail.canPlayCall
  }

  private get getClientTitle(): string {
    return this.customerDetail.clientTitle
  }

  private set getClientTitle(newValue: string) {
    this.customerDetail.clientTitle = newValue
  }

  private get getFirstName(): string {
    return this.customerDetail.clientForename
  }

  private set getFirstName(newValue: string) {
    this.customerDetail.clientForename = newValue
  }

  private get getLastName(): string {
    return this.customerDetail.clientLastName
  }

  private set getLastName(newValue: string) {
    this.customerDetail.clientLastName = newValue
  }

  private get getSecondaryClientTitle(): string | null {
    return this.customerDetail.secondaryClientTitle
  }

  private set getSecondaryClientTitle(newValue: string | null) {
    this.customerDetail.secondaryClientTitle = newValue
  }

  private get getSecondaryFirstName(): string | null {
    return this.customerDetail.secondaryClientForename
  }

  private set getSecondaryFirstName(newValue: string | null) {
    this.customerDetail.secondaryClientForename = newValue
  }

  private get getSecondaryLastName(): string | null {
    return this.customerDetail.secondaryClientLastName
  }

  private set getSecondaryLastName(newValue: string | null) {
    this.customerDetail.secondaryClientLastName = newValue
  }

  private get getPolicyNumber(): string {
    return this.customerDetail.policyNumber
  }

  private set getPolicyNumber(newValue: string) {
    this.customerDetail.policyNumber = newValue
  }

  private get getAddressLine1(): string {
    return this.customerDetail.addressLine1
  }

  private set getAddressLine1(newValue: string) {
    this.customerDetail.addressLine1 = newValue
  }

  private get getAddressLine2(): string {
    return this.customerDetail.addressLine2
  }

  private set getAddressLine2(newValue: string) {
    this.customerDetail.addressLine2 = newValue
  }

  private get getAddressLine3(): string {
    return this.customerDetail.addressLine3
  }

  private set getAddressLine3(newValue: string) {
    this.customerDetail.addressLine3 = newValue
  }

  private get getAddressLine4(): string {
    return this.customerDetail.addressLine4
  }

  private set getAddressLine4(newValue: string) {
    this.customerDetail.addressLine4 = newValue
  }

  private get getPostCode(): string {
    return this.customerDetail.postCode
  }

  private set getPostCode(newValue: string) {
    this.customerDetail.postCode = newValue.toUpperCase()
  }

  private get customerRef(): string {
    return this.updateCustomerRef.customerRef
  }

  private set customerRef(newValue: string) {
    this.updateCustomerRef.customerRef = newValue
  }

  private getCustomerDetails(): void {
    if (this.job) {
      // fill customer details
      this.getClientTitle = this.job.clientTitle
      this.getFirstName = this.job.clientForename
      this.getLastName = this.job.clientLastName
      this.getSecondaryClientTitle = this.job.secondaryClientTitle
      this.getSecondaryFirstName = this.job.secondaryClientForename
      this.getSecondaryLastName = this.job.secondaryClientLastName
      this.getAddressLine1 = this.job.addressLine1
      this.getAddressLine2 = this.job.addressLine2
      this.getAddressLine3 = this.job.addressLine3
      this.getAddressLine4 = this.job.addressLine4
      this.getPostCode = this.job.postCode
      this.customerRef = this.job.customerRef
      // open popup
      this.changeAddress = true
    }
  }

  private created() {
    this.SetNomineeRelations()
    if (this.job && this.job.removedEmergencies && this.job.removedEmergencies.length > 0) {
      this.getRemoveEmergencyReasonList()
    }
  }

  private openChangeAddressPopup(open) {
    if (!this.showUpdateCustomerDetailButton) {
      return
    }
    if (open) {
      return this.getCustomerDetails()
    } else {
      this.changeAddress = false
    }
  }

  private getRelationWithNominee(id: string) {
    if (this.getNomineeRelations) {
      const relation: MasterRecord | undefined = this.getNomineeRelations.find((e) => e.id === id)
      if (relation) {
        return relation.description
      } else {
        return null
      }
    }
  }

  private async saveAddress() {
    // validate fields
    const subs = this.$refs.addCustomerAddress as CustomerAddress
    const result = await subs.$validator.validateAll()
    if (!result) {
      Shared.setValidationFocus(subs.$el as HTMLElement)
      return
    }

    if (this.job && this.job.jobType === 'SI') {
      const input = this.$refs.clientReferenceRef as HTMLElement
      if (!this.customerRef) {
        input.focus()
        return
      }
    }

    this.isLoading = true
    this.customerDetail.jobId = this.jobId

    if (this.job && this.job.jobType === 'SI') {
      this.updateCustomerRef.jobId = this.jobId
      // save client reference
      JobController.UpdateCustomerRef(this.updateCustomerRef)
        .then((res: boolean) => {
          if (!res) {
            alert('error updating client reference, please try again')
            this.isLoading = false
            return
          }
        })
        .catch((err: any) => {
          this.isLoading = false
          eventBus.$emit('errorHandler', 'Error updating client reference, please try again', true)
        })
    }

    // save updated customer information
    JobController.UpdateCustomerAddressDetail(this.customerDetail)
      .then((res: boolean) => {
        if (res) {
          this.isLoading = false
          this.changeAddress = false
        } else {
          alert('error updating customer address, please try again')
          this.isLoading = false
          return
        }
      })
      .catch((err: any) => {
        this.isLoading = false
        eventBus.$emit('errorHandler', 'Error updating customer address, please try again', true)
      })
  }

  // Set list of nominee relations.
  private SetNomineeRelations(): void {
    if (this.getNomineeRelations.length === 0) {
      const recordType: string = RecordType[RecordType.NomineeRelation]
      MasterRecordController.GetMasterRecords(recordType).then((res: MasterRecord[]) => {
        if (res) {
          storeMutations.setNomineeRelations(res)
          this.nomineeRelationList = this.getNomineeRelations.slice(0)
        }
      })
    } else {
      this.nomineeRelationList = this.getNomineeRelations.slice(0)
    }
  }

  private get isUploadDocumentAllowed(): boolean {
    if (this.job) {
      return this.job.jobType !== 'HE' ? true : false
    }
    return false
  }

  private get isUserRoleAdministrator(): boolean {
    return (
      store.Instance.state.SessionDetail.detailRecordType === 'UserDetail' &&
      (store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'SystemAdministrator' ||
        store.Instance.state.SessionDetail.detailRecord.UserDetail.roleName === 'Administrator')
    )
  }

  private openConfirmationDialogToRemoveEmergency(emergencyId: string) {
    this.emergencyIdToRemove = emergencyId
    if (!this.emergencyIdToRemove) {
      eventBus.$emit('showSnackbar', 'Cannot remove null emergency.')
      return
    }
    Shared.confirmationPopup.open(
      'Are you sure you wish to remove this emergency?',
      '',
      '',
      'No',
      'Yes',
      this,
      'onConfirmRemoveEmergency',
      ''
    )
  }

  private onConfirmRemoveEmergency() {
    if (this.removeEmergencyReasonList.length === 0) {
      this.getRemoveEmergencyReasonList()
    }
    this.removeEmergencyFromJobModel = new RemoveEmergencyFromJobModel()
    this.removeEmergencyDialog = true
  }

  // Get list of remove emergency reason.
  private getRemoveEmergencyReasonList(): void {
    this.isLoading = true
    const recordType: string = RecordType[RecordType.RemoveEmergencyFromJobReason]
    this.configureDropdownType = RecordType[RecordType.RemoveEmergencyFromJobReason]
    MasterRecordController.GetMasterRecords(recordType)
      .then((res: MasterRecord[]) => {
        if (res) {
          this.allRemoveEmergencyReasonList = res
          this.removeEmergencyReasonList = this.allRemoveEmergencyReasonList.filter((e) => e.isDeleted === false)
          if (this.isUserRoleAdministrator) {
            const removeEmergencyReasonConfigure: any = {}
            removeEmergencyReasonConfigure.description = 'Configure'
            this.removeEmergencyReasonList.push(removeEmergencyReasonConfigure)
          }
        }
        this.isLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading' + recordType + 'list, please try again', true)
        this.isLoading = false
      })
  }

  // when 'configure' is selected as an option from dropdown Admin can perform add/update/delete actions on remove emergency reason type.
  private onRemoveEmergencyReasonChange(selectedItems: any) {
    if (selectedItems === 'Configure') {
      this.configureDropdownType = RecordType[RecordType.RemoveEmergencyFromJobReason]
      this.configureDropdownDialog = true
    }
  }

  private onConfigureDropdownDialogClose(Items: any[]) {
    this.configureDropdownDialog = false
    this.removeEmergencyFromJobModel.reason = ''
    const deletedRecord: MasterRecord | undefined = this.removeEmergencyReasonList.find((e) => e.isDeleted === true)
    this.removeEmergencyReasonList = Items.slice(0)
    if (deletedRecord !== undefined) {
      this.removeEmergencyReasonList.push(deletedRecord)
    }
    if (this.isUserRoleAdministrator) {
      const removeEmergencyReason: any = {}
      removeEmergencyReason.description = 'Configure'
      this.removeEmergencyReasonList.push(removeEmergencyReason)
    }
  }

  private async validate(): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll('frmRemoveEmergency')

    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return result
  }

  private get showRemoveEmergencyButton() {
    if (Store.Instance.state.SessionDetail.canRemoveEmergency) {
      if (this.job && this.getEmergencyList.length > 1 && this.job.jobType === 'HE') {
        if (
          this.job.status !== 'Completed' &&
          this.job.status !== 'Cancelled' &&
          !this.job.cancellationReason &&
          !this.job.isJobReopened
        ) {
          return true
        }
        return false
      }
    }
    return false
  }

  private removeEmergency() {
    this.validate()
      .then((result: boolean) => {
        if (result) {
          this.removeEmergencyFromJobModel.jobId = this.jobId
          this.removeEmergencyFromJobModel.emergencyId = this.emergencyIdToRemove
          this.removeEmergencyLoading = true
          JobController.RemoveEmergencyFromJob(this.removeEmergencyFromJobModel)
            .then((res: boolean | string) => {
              if (res) {
                this.snackbarText = typeof res === 'string' ? res : 'Emergency Removed Successfully!'
              }
              this.snackbar = true
              this.removeEmergencyLoading = false
              this.removeEmergencyDialog = false
            })
            .catch((err: any) => {
              this.removeEmergencyLoading = false
              eventBus.$emit('errorHandler', 'Error removing emergency from job , please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private getRemoveEmergencyReason(reasonId: string) {
    const reason = this.allRemoveEmergencyReasonList.find((e) => e.id === reasonId)
    return reason && reason.description ? reason.description : ''
  }

  private get emergencyTypes() {
    return storeGetters.getEmergencies()
  }

  private getEmergencyFileURL(emergencyTypeId: number) {
    const emergency = this.emergencyTypes.find((e) => e.emergencyId === emergencyTypeId)
    return emergency && emergency.fileURL ? emergency.fileURL : ''
  }

  private onPhoneNumberClick(contactNumber: string) {
    // put the call parameters together and pass them over
    const callParameters = new CallParameters()
    callParameters.to = contactNumber
    callParameters.jobId = this.jobId
    eventBus.$emit('callIconClick', callParameters)
  }

  private jobTypeLabel(name: string) {
    return Shared.JobTypeLabel(name, this.job ? this.job.jobType : 'HE')
  }

  private get siJobDelay() {
    return storeGetters.getSIJobDelay(this.jobId)
  }

  private get usJobDelay() {
    return storeGetters.getUSJobDelay(this.jobId)
  }

  private addJobDelayCard() {
    eventBus.$emit('addJobDelayCard')
  }

  private get showUpdateCustomerDetailButton() {
    return this.job
      ? this.job.jobType === 'HE'
        ? this.job.status !== 'Completed' && !this.job.isJobReopened
        : true
      : false
  }
}
</script>

<style scoped>
.prperty-detail img {
  width: 100%;
}
.property-address {
  position: absolute;
  bottom: 10px;
  right: 6px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  padding: 13px;
}
.prperty-detail {
  position: relative;
}
.prperty-detail address {
  font-style: normal;
}
.client-detail .v-avatar {
  height: 32px !important;
  width: 32px !important;
}
.pro-address > address {
  position: relative;
  padding-left: 30px !important;
  padding-right: 40px !important;
}
.pro-address > address .map-icon {
  position: absolute;
  left: -3px;
}
.iframe {
  height: 100%;
  width: 100%;
  min-height: 300px;
}
.scroll-content .vb-content {
  max-height: 300px;
}
pre {
  font-family: inherit;
  word-break: break-all;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.change-ad-btn {
  position: absolute !important;
  margin: 0px;
  top: 12px;
  right: 10px;
}
.change-ad-btn i {
  font-size: 18px;
  color: #fff;
}
.change-ad-btn .edit-number {
  font-size: 11px;
  height: 18px;
  width: 18px;
  background-color: #009688;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  position: absolute;
  right: -8px;
  padding: 0px 0px;
  line-height: 17px;
  top: -8px;
}
.prefrance-contact-icon {
  padding-left: 20px;
  position: relative;
  word-break: break-all;
}
.prefrance-contact-icon > b {
  position: absolute;
  left: 0px;
}
@media (max-width: 1279px) {
  .prperty-detail {
    margin-bottom: 10px;
  }
}

.fnol-emergency-details .img-content img,
.remove-emergency-details .img-content img {
  width: 14px;
  height: 16px;
}

.fnol-emergency-details > div {
  position: relative;
  padding-left: 5px;
  margin-bottom: 8px;
}

.fnol-emergency-details .img-content,
.remove-emergency-details .img-content img {
  position: relative;
  left: -5px;
  top: 2px;
  display: inline-block;
}
.bold-select {
  font-weight: 600;
}
.input-notes {
  margin-left: 14px;
}
</style>
