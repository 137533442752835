<template>
  <div>
    <h4 class="mb-2">Customer Availability</h4>
    <div v-for="(timeSlotKey, timeSlotKeyIndex) in timeSlotDayKeys" :key="`${timeSlotKey}-${timeSlotKeyIndex}`">
      <div v-if="hasDayAnyTimeSlots(timeSlotKey)" class="mb-2">
        <h4>{{ timeSlotKey }}</h4>
        <v-chip
          v-for="(day, index) in retrieveTimeSlotsForDay(timeSlotKey)"
          :key="`${day.startTime}-${index}`"
          flat
          outline
          class="white--text"
          color="primary"
          dark
          small
          >{{ day.startTime }} - {{ day.endTime }}</v-chip
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { CustomerAvailability } from '@/api/models/deployment/contractor/customer-availability-response'
import { Prop } from 'vue-property-decorator'
import { TimeSlot } from '@/api/models/deployment/contractor/time-slot-response'
import moment from 'moment'

@Component
export default class CustomerAvailabilityDisplay extends Vue {
  @Prop() private availability: CustomerAvailability

  private dateFormatter = 'dddd, Do MMMM'

  public get timeSlotDayKeys() {
    return [
      ...new Set(
        this.availability.timeSlots
          .sort((a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf())
          .map((slot) => moment(slot.startTime).format('dddd, Do MMMM'))
      ),
    ]
  }

  public get timeSlotsGroupedByDay(): { [key: string]: TimeSlot[] } {
    return this.availability.timeSlots.reduce((acc, slot) => {
      const date = moment(slot.startTime).format(this.dateFormatter)
      return {
        ...acc,
        [date]: [...(acc[date] || []), slot].sort(
          (a, b) => moment(a.startTime).valueOf() - moment(b.startTime).valueOf()
        ),
      }
    }, {})
  }

  // This is for when we have times such as 3-4, 4-5, 5-6, 6-7
  // We want to group these so that we can display 3-7 to the user, instead of showing
  // every individual timeslot
  public groupConsecutiveSlots(slots: TimeSlot[]): TimeSlot[][] {
    if (!slots?.length) {
      return []
    }
    const groups: TimeSlot[][] = []
    let currentGroup: TimeSlot[] = []

    for (const slot of slots) {
      if (
        !currentGroup.length ||
        slot.startTime.getTime() === currentGroup[currentGroup.length - 1].endTime.getTime()
      ) {
        currentGroup.push(slot)
      } else {
        groups.push(currentGroup)
        currentGroup = [slot]
      }
    }

    if (currentGroup.length) {
      groups.push(currentGroup)
    }

    return groups
  }

  public retrieveTimeSlotsForDay(dayKey: string) {
    const slots = this.groupConsecutiveSlots(this.timeSlotsGroupedByDay[dayKey])
    return slots.map((slotArray) => {
      const firstSlot = slotArray[0]
      const lastSlot = slotArray[slotArray.length - 1]
      return {
        startTime: firstSlot.startTime.getHours().toString().padStart(2, '0'),
        endTime: lastSlot.endTime.getHours().toString().padStart(2, '0'),
      }
    })
  }

  public hasDayAnyTimeSlots(dayKey: string) {
    return this.availability.timeSlots.some((x) => moment(x.startTime).format(this.dateFormatter) === dayKey)
  }
}
</script>
