var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("Customer Availability")]),
      _vm._l(_vm.timeSlotDayKeys, function (timeSlotKey, timeSlotKeyIndex) {
        return _c("div", { key: `${timeSlotKey}-${timeSlotKeyIndex}` }, [
          _vm.hasDayAnyTimeSlots(timeSlotKey)
            ? _c(
                "div",
                { staticClass: "mb-2" },
                [
                  _c("h4", [_vm._v(_vm._s(timeSlotKey))]),
                  _vm._l(
                    _vm.retrieveTimeSlotsForDay(timeSlotKey),
                    function (day, index) {
                      return _c(
                        "v-chip",
                        {
                          key: `${day.startTime}-${index}`,
                          staticClass: "white--text",
                          attrs: {
                            flat: "",
                            outline: "",
                            color: "primary",
                            dark: "",
                            small: "",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(day.startTime) + " - " + _vm._s(day.endTime)
                          ),
                        ]
                      )
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }