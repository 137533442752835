import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import UpdateUserModel from '@/models/users/update-user-model'
import UserCreateModel from '@/models/users/create-user-model'
import UserDetailModel from '@/models/users/user-detail-model'
import UserListItemModel from '@/models/users/user-list-item-model'
import UserPermissionsModel from '@/models/users/user-permissions-model'
import UserRoleListItemModel from '@/models/users/user-role-list-item-model'
import UserTeamListItemModel from '@/models/users/user-team-list-item-model'
import UpdateUserResponseModel from '@/models/users/update-user-response-model'
import RelativeUserPermissionsModel from '@/models/users/relative-user-permissions-model'
import { SetProfilePictureResponse } from './models/users'

export default class UsersController {
  public static async GetPermissionsAsync(): Promise<UserPermissionsModel> {
    const res = await CallCentreApi.get<UserPermissionsModel>('users/permissions')
    return res.data
  }

  public static async GetRelativePermissionsAsync(userId: string): Promise<RelativeUserPermissionsModel | null> {
    const res = await CallCentreApi.get<RelativeUserPermissionsModel>(`users/permissions/${userId}`)
    return res.data
  }

  public static async GetUsersAsync(): Promise<UserListItemModel[]> {
    const res = await CallCentreApi.get<UserListItemModel[]>('users')
    return res.data
  }

  public static async GetUserByIdAsync(userId: string): Promise<UserDetailModel | null> {
    const res = await CallCentreApi.get<UserDetailModel>(`users/${userId}`)
    return res.data
  }

  public static async GetTeamsAsync(): Promise<UserTeamListItemModel[]> {
    const res = await CallCentreApi.get<UserTeamListItemModel[]>(`users/teams`)
    return res.data
  }

  public static async GetRolesAsync(): Promise<UserRoleListItemModel[]> {
    const res = await CallCentreApi.get<UserRoleListItemModel[]>(`users/roles`)
    return res.data
  }

  public static async CreateUserAsync(user: UserCreateModel): Promise<UserDetailModel> {
    const res = await CallCentreApi.post<UserDetailModel>(`users`, user)
    return res.data
  }

  public static async UpdateUserAsync(user: UpdateUserModel): Promise<UpdateUserResponseModel> {
    const res = await CallCentreApi.patch<UpdateUserResponseModel>(`users/${user.id}`, user)
    return res.data
  }

  public static async DeleteUserAsync(userId: string): Promise<void> {
    await CallCentreApi.delete(`users/${userId}`)
  }

  public static async setProfilePicture(userId: string, file: File): Promise<SetProfilePictureResponse> {
    const response = await CallCentreApi.put<SetProfilePictureResponse>(
      `users/${userId}/profile-picture`,
      { picture: file },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
    return response.data
  }

  public static async deleteProfilePicture(engineerId: string): Promise<void> {
    await CallCentreApi.delete(`users/${engineerId}/profile-picture`)
  }
}
