import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import PolicyRouteToMarketModel from '@/models/policy/PolicyRouteToMarketModel'
import { Environment } from '@/common/environment'
import { mutationTree as mutations } from '@/storeMutations'
import { getterTree as getters } from '@/storeGetters'
import { actions } from '@/storeActions'
import Job from '@/models/Job'
import { Moment } from 'moment'
import UserModel from '@/models/user/UserModel'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import MasterRecord from '@/models/MasterRecord'
import TradeModel from './models/policyHolder/TradeModel'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import SessionDetail from '@/models/auth/SessionDetail'
import SessionController from '@/api/sessionController'
import InsurerModel from '@/models/policy/InsurerModel'
import ClientTemplateModel from './models/client/ClientTemplateModel'
import BoilerBreakdownQA from './models/claim/BoilerBreakdownQA'
import InsurerPhoneNumberModel from './models/client/InsurerPhoneNumberModel'
import SubsidenceConsultantModel from '@/models/SubsidenceConsultantModel'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import { Worker } from '@/components/dashboard/worker'
import ReportDetail from '@/models/ReportDetail'
import TestingProgressDetail from './models/siteInvestigation/TestingProgressDetail'
import eventBus from '@/common/bus'
import EnvironmentController from './api/environmentController'
import TemplateCookieRecord from './models/cookies/template-cookie-record'
import TradeDelay from './models/delay/TradeDelay'
import { cookieModule } from '@/vuex/modules/cookie-module'
import { clientModule } from '@/vuex/modules/client-module'
import { delayModule } from '@/vuex/modules/delay-module'
import { complaintModule } from '@/vuex/modules/complaint-module'
import { authModule } from '@/vuex/modules/auth-module'
import { jobModule } from '@/vuex/modules/job-module'
import { engineerModule } from './vuex/modules/engineer-module'
import { jobRequestModule } from './vuex/modules/job-request-module'
import { usersModule } from './vuex/modules/users-module'
import { dashboardsModule } from './vuex/modules/dashboards-module'
import { snackbarModule } from './vuex/modules/snackbar-module'
import { contractorModule } from '@/vuex/modules/contractor-module'
import { agentModule } from './vuex/modules/agent-module'
import ContractorModel from './models/contractor/ContractorModel'
import { PostcodeRegion } from './models/contractor/RegionsConfiguration'
import { Postcode } from './models/contractor/Postcode'
import { policyModule } from './vuex/modules/policy-module'
import { heWizardModule } from './vuex/modules/he-wizard-module'
import PolicySchedule from './models/policySchedule/policySchedule'
import { logger, onTokenChanged } from './plugins/datadog'

// tslint:disable:no-console
Vue.use(Vuex)

// interface that models the vuex state for the application
export interface State {
  SessionDetail: SessionDetail
  UserUpdatedAt: Moment | null
  Environment: Environment
  Jobs: { [id: string]: Job }
  JobRefreshRequested: boolean
  LastAddedNewJobAt: Moment | null
  PolicyRouteToMarketList: PolicyRouteToMarketModel[]
  Users: UserModel[]
  CurrentCallSid: string
  CurrentCallClientId: string
  CurrentCallPolicySchedule: PolicySchedule | null
  Emergencies: EmergencyModel[]
  LoqateApiKey: string
  DelayCodes: MasterRecord[]
  Trades: TradeModel[]
  Insurers: InsurerModel[]
  InsurersPortals: ClientTemplateModel[]
  PostCodeDistricts: any[]
  BoilerBreakdownQA: BoilerBreakdownQA[]
  CustomersPhoneNumber: InsurerPhoneNumberModel[]
  NomineeRelations: MasterRecord[]
  SubsidenceConsultants: SubsidenceConsultantModel[]
  ClaimManagementCompanies: ClaimManagementCompanyModel[]
  Workers: Worker[]
  ContractorRegistrationStatus: string
  ReportDetailRecords: ReportDetail[]
  EnquiryCategories: MasterRecord[]
  TestingProgressDetails: TestingProgressDetail[]
  TemplateCookieRecord: TemplateCookieRecord | null
  TradeDelays: TradeDelay[]
  Contractors: ContractorModel[]
  Regions: PostcodeRegion[]
  EscalationReasons: MasterRecord[]
  Outcodes: Postcode[]
}

class StoreWrapper {
  public static get Instance(): Store<State> {
    if (StoreWrapper.store) {
      return StoreWrapper.store
    } else {
      return new Store<State>({})
    }
  }

  public static LoadStore(env: Environment, oidcSettings: any): Store<State> {
    const state: State = {
      SessionDetail: new SessionDetail(),
      UserUpdatedAt: null,
      Environment: env,
      Jobs: {},
      JobRefreshRequested: false,
      LastAddedNewJobAt: null,
      PolicyRouteToMarketList: [],
      Users: [],
      CurrentCallSid: '',
      CurrentCallClientId: '',
      CurrentCallPolicySchedule: null,
      Emergencies: [],
      LoqateApiKey: '',
      DelayCodes: [],
      Trades: [],
      Insurers: [],
      InsurersPortals: [],
      PostCodeDistricts: [],
      BoilerBreakdownQA: [],
      CustomersPhoneNumber: [],
      NomineeRelations: [],
      SubsidenceConsultants: [],
      ClaimManagementCompanies: [],
      Workers: [],
      ContractorRegistrationStatus: '',
      ReportDetailRecords: [],
      EnquiryCategories: [],
      TestingProgressDetails: [],
      TemplateCookieRecord: null,
      TradeDelays: [],
      Contractors: [],
      Regions: [],
      EscalationReasons: [],
      Outcodes: [],
    }

    StoreWrapper.store = new Vuex.Store<State>({
      modules: {
        authModule,
        cookieModule,
        delayModule,
        clientModule,
        complaintModule,
        contractorModule,
        jobModule,
        usersModule,
        dashboardsModule,
        engineerModule,
        jobRequestModule,
        snackbarModule,
        agentModule,
        policyModule,
        heWizardModule,
        oidcStore: vuexOidcCreateStoreModule(
          oidcSettings,
          { dispatchEventsOnWindow: true },
          {
            userLoaded: async (user) => {
              logger.info('OIDC user is loaded')
              localStorage.setItem('simplifi-auth', user.access_token)
              onTokenChanged(user.access_token)
              await SessionController.LoadSession(user.access_token)
              await EnvironmentController.RetrieveEnvironmentSecrets(user.access_token)
            },
            userUnloaded: () => {
              logger.info('OIDC user is unloaded and Session Detail has been cleared.')
              SessionController.ClearSession()
            },
            accessTokenExpiring: () => logger.info('OIDC Access token will expire'),
            accessTokenExpired: () => logger.info('Access token has expired'),
            silentRenewError: () => logger.info('Silent Renew Error - OIDC user is unloaded'),
            userSignedOut: () => {
              logger.info('OIDC user is signed out')
              SessionController.ClearSession()
            },
            oidcError: (error) => eventBus.$emit('errorHandler', `OIDC Error: ${error.message}`),
          }
        ),
      },
      state,
      mutations,
      actions,
      getters,
    })
    return StoreWrapper.Instance
  }

  private static store: Store<State>
}

export default StoreWrapper
