var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "", "pa-2": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "px-2": "", "py-1": "" } },
        [
          _c("h4", [_vm._v("Please select an engineer to assign the job to")]),
          _c("v-select", {
            staticClass: "hide-option",
            attrs: {
              items: _vm.availableEngineers,
              label: "Select Engineer",
              "item-text": "name",
              "item-value": "id",
              "persistent-hint": "",
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ item }) {
                  return [
                    _c("v-avatar", [
                      item.profileImageThumbnailUrl
                        ? _c("img", {
                            attrs: { src: item.profileImageThumbnailUrl },
                          })
                        : _c("img", { attrs: { src: "/img/unknownuser.png" } }),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(item.firstName) +
                        " " +
                        _vm._s(item.lastName) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("v-list-tile-avatar", [
                      item.profileImageThumbnailUrl
                        ? _c("img", {
                            attrs: { src: item.profileImageThumbnailUrl },
                          })
                        : _c("img", { attrs: { src: "/img/unknownuser.png" } }),
                    ]),
                    _c(
                      "v-list-tile-content",
                      [
                        _c("v-list-tile-title", [
                          _vm._v(
                            " " +
                              _vm._s(item.firstName) +
                              " " +
                              _vm._s(item.lastName) +
                              " "
                          ),
                        ]),
                        _c("v-list-tile-sub-title", [
                          _vm._v(" " + _vm._s(item.email) + " "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedEngineerId,
              callback: function ($$v) {
                _vm.selectedEngineerId = $$v
              },
              expression: "selectedEngineerId",
            },
          }),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-2" }),
      _c(
        "v-layout",
        { staticClass: "justify-end" },
        [
          _c(
            "v-flex",
            { staticClass: "d-flex justify-end", attrs: { xs5: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "grey white--text",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("engineerAssigned")
                    },
                  },
                },
                [_vm._v(" Assign Later ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "green white--text px-6 w-20",
                  on: { click: _vm.assignEngineer },
                },
                [_vm._v(" Assign Engineer ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }