var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "wrapper", class: { "overflow-y-hidden": _vm.loading } },
    [
      _c(
        "v-layout",
        {
          staticClass: "profile-banner pt-3",
          attrs: { "align-center": "", "flex-col": "" },
        },
        [
          _c("ProfileImage", {
            attrs: { image: _vm.engineer.profileImageUrl },
            on: { "update:image": _vm.onProfilePictureChanged },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c(
            "div",
            { staticClass: "fields" },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  label: "First Name",
                  maxlength: "50",
                  required: "",
                  "data-vv-scope": "engineerDetails",
                  "data-vv-name": "First Name",
                  "error-messages": _vm.errors.collect("First Name"),
                },
                model: {
                  value: _vm.engineer.firstName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.engineer,
                      "firstName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "engineer.firstName",
                },
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  label: "Last Name",
                  maxlength: "50",
                  required: "",
                  "data-vv-scope": "engineerDetails",
                  "data-vv-name": "Last Name",
                  "error-messages": _vm.errors.collect("Last Name"),
                },
                model: {
                  value: _vm.engineer.lastName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.engineer,
                      "lastName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "engineer.lastName",
                },
              }),
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: {
                  label: "Email",
                  maxlength: "50",
                  required: "",
                  "data-vv-scope": "engineerDetails",
                  "data-vv-name": "Email",
                  "error-messages": _vm.errors.collect("Email"),
                  name: "Email",
                },
                model: {
                  value: _vm.engineer.email,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.engineer,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "engineer.email",
                },
              }),
              _c("PhoneNumber", {
                ref: "engineerContactNumber",
                staticClass: "phone v-text-field",
                attrs: {
                  "passed-phone-number": _vm.engineer.contactNumber,
                  "is-validation-required": true,
                },
                on: {
                  phoneNumberUpdated: (phone) =>
                    (_vm.engineer.contactNumber = phone),
                },
              }),
            ],
            1
          ),
          _c(
            "h3",
            { staticClass: "text-lg v-label theme--light font-weight-regular" },
            [_vm._v("New Claims Notification Preferences")]
          ),
          _c(
            "div",
            { staticClass: "d-flex mb-4" },
            [
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  small: "",
                  label: "Email",
                  "hide-details": "",
                },
                model: {
                  value: _vm.comms.autodeployByEmail,
                  callback: function ($$v) {
                    _vm.$set(_vm.comms, "autodeployByEmail", $$v)
                  },
                  expression: "comms.autodeployByEmail",
                },
              }),
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  small: "",
                  label: "SMS",
                  "hide-details": "",
                },
                model: {
                  value: _vm.comms.autodeployBySms,
                  callback: function ($$v) {
                    _vm.$set(_vm.comms, "autodeployBySms", $$v)
                  },
                  expression: "comms.autodeployBySms",
                },
              }),
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  small: "",
                  label: "App",
                  "hide-details": "",
                },
                model: {
                  value: _vm.comms.autodeployByEngineerApp,
                  callback: function ($$v) {
                    _vm.$set(_vm.comms, "autodeployByEngineerApp", $$v)
                  },
                  expression: "comms.autodeployByEngineerApp",
                },
              }),
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  small: "",
                  label: "WhatsApp",
                  "hide-details": "",
                },
                model: {
                  value: _vm.comms.autodeployByWhatsApp,
                  callback: function ($$v) {
                    _vm.$set(_vm.comms, "autodeployByWhatsApp", $$v)
                  },
                  expression: "comms.autodeployByWhatsApp",
                },
              }),
            ],
            1
          ),
          _c("v-textarea", {
            attrs: { label: "Address", maxlength: "500", rows: "3" },
            model: {
              value: _vm.engineer.address,
              callback: function ($$v) {
                _vm.$set(
                  _vm.engineer,
                  "address",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "engineer.address",
            },
          }),
          _c("v-switch", {
            attrs: {
              color: "primary",
              small: "",
              label: "Ability to use Portal",
              "hide-details": "",
            },
            model: {
              value: _vm.engineer.hasPortalAccess,
              callback: function ($$v) {
                _vm.$set(_vm.engineer, "hasPortalAccess", $$v)
              },
              expression: "engineer.hasPortalAccess",
            },
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.availableTrades,
              label: "Select Trade",
              "item-text": "description",
              "item-value": "tradeId",
              clearable: "",
              chips: "",
              "deletable-chips": "",
              "hide-details": "",
              multiple: "",
              "persistent-hint": "",
              "return-object": "",
              readonly: _vm.isUserContractor,
            },
            on: { change: _vm.onTradesChanged },
            model: {
              value: _vm.sortedTrades,
              callback: function ($$v) {
                _vm.sortedTrades = $$v
              },
              expression: "sortedTrades",
            },
          }),
          _vm._l(_vm.sortedTrades, function (trade) {
            return _c(
              "v-flex",
              {
                key: trade.tradeId,
                staticClass: "engineer-timeslot",
                attrs: { xs12: "" },
              },
              [
                _c("TimeSlotPicker", {
                  attrs: {
                    "header-text": trade.description,
                    "filter-by-date": false,
                    model: trade,
                    "slot-items": _vm.getTradeAvailability(trade.tradeId),
                    "slot-type": "today",
                  },
                  on: { updateSlotItems: _vm.onTimeSlotsChanged },
                }),
              ],
              1
            )
          }),
          _c(
            "v-layout",
            {
              staticClass: "modal-actions",
              attrs: {
                row: "",
                "justify-end": "",
                sticky: "",
                "b-0": "",
                "pa-2": "",
              },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary" },
                  on: { click: _vm.close },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.save } },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "loader" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 64,
                  width: 7,
                  indeterminate: "",
                  color: "primary",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }