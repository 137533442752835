<template>
  <v-card v-if="clientUser">
    <v-layout align-center flex-col class="profile-banner pt-3">
      <ProfileImage :image="clientUser.profileImageUrl" @update:image="onProfilePictureChanged" />
    </v-layout>
    <v-card-text class="pa-3 scroll-content-dialog">
      <v-layout row wrap>
        <v-flex xs12>
          <v-text-field
            v-model.trim="clientUser.firstName"
            v-validate="'required'"
            label="First Name"
            maxlength="50"
            class="required"
            required
            data-vv-scope="frmClientUser"
            data-vv-name="First Name"
            :error-messages="errors.collect('First Name')"
            name="firstName"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model.trim="clientUser.lastName"
            v-validate="'required'"
            label="Last Name"
            maxlength="50"
            class="required"
            required
            data-vv-scope="frmClientUser"
            data-vv-name="Last Name"
            :error-messages="errors.collect('Last Name')"
            name="lastName"
          ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field
            v-model.trim="clientUser.email"
            v-validate="'required|email'"
            label="Email"
            maxlength="50"
            class="required"
            required
            data-vv-scope="frmClientUser"
            data-vv-name="Email"
            :error-messages="errors.collect('Email')"
            name="email"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 mt-1>
          <v-switch
            v-model="clientUser.hasPortalAccess"
            color="primary"
            small
            label="Ability to use Portal"
            hide-details
          ></v-switch>
        </v-flex>
        <v-flex xs12 mt-1>
          <v-layout>
            <v-flex xs6 pt-3>
              <v-switch
                v-model="isAffinityPartner"
                color="primary"
                small
                label="Affinity Partner"
                hide-details
              ></v-switch>
            </v-flex>
            <v-flex xs6>
              <v-autocomplete
                v-show="isAffinityPartner"
                v-model="selectedPolicies"
                v-validate="'required'"
                label="Search policy"
                maxlength="100"
                required
                multiple
                class="required policy-list"
                return-object
                :items="policyList"
                :loading="policySearchRunning"
                item-text="policyBasicDetail.name"
                item-value="policyBasicDetail.policyId"
                data-vv-name="Search policy"
                :error-messages="errors.collect('Search policy')"
                :disabled="!isAffinityPartner"
                @change="onPolicySelectionChange(selectedPolicies, $event)"
              >
                <template slot="item" slot-scope="data">
                  <v-list-tile>
                    <v-list-tile-action>
                      <v-checkbox
                        v-model="policyCheckboxes[policyList.indexOf(data.item)].checked"
                        color="primary"
                      ></v-checkbox>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title
                        :class="data.item.policyBasicDetail.isDeleted ? 'deleted-item' : ''"
                        v-html="data.item.policyBasicDetail.name"
                      ></v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-autocomplete>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-flex v-show="errorMsg" xs12>
        <div class="pt-3 common-error-message">
          <div class="red pa-2 d-block lighten-4">
            <span>
              <b>{{ errorMsg }}</b>
            </span>
          </div>
        </div>
      </v-flex>
    </v-card-text>
    <v-divider />
    <v-card-actions class="px-3">
      <v-spacer></v-spacer>
      <v-btn @click.native="Close">Close</v-btn>
      <v-btn :disabled="isLoading" :loading="isLoading" color="primary" class="mr-0 submit-btn" @click.native="Save">
        submit
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import ClientUserDetailModel from '@/models/client/ClientUserDetailModel'
import ClientUserController from '@/api/clientUserController'
import PolicyModel from '@/models/policy/PolicyModel'
import eventBus from '@/common/bus'
import ProfileImage from '@/components/profile-image/ProfileImage.vue'

@Component({
  components: { ProfileImage },
})
export default class ClientUser extends Vue {
  @Prop() private clientUserItem: ClientUserDetailModel | null
  @Prop() private policies: PolicyModel[]
  @Prop() private policySearchRunning: boolean

  private profilePictureChanged: boolean = false
  public newProfilePicture: File | null = null

  private isLoading = false
  private errorMsg = ''
  private affinityPartner = false
  private selectedPoliciesField: PolicyModel[] = []
  private policy: PolicyModel | null = null
  private policyList: PolicyModel[] = []
  private policyCheckboxes: any = []

  public created() {
    if (this.clientUser) {
      this.affinityPartner = this.clientUser.isAffinityPartner
      if (this.clientUser.affinityPartnerPolicyScheduleIds) {
        this.selectedPoliciesField = this.policies.filter((x) =>
          this.clientUser!.affinityPartnerPolicyScheduleIds!.includes(x.policyBasicDetail.policyId)
        )
      }
    }
  }

  private mounted() {
    this.policyList =
      this.clientUser &&
      this.clientUser.affinityPartnerPolicyScheduleIds &&
      this.clientUser.affinityPartnerPolicyScheduleIds.length > 0
        ? this.policies.filter(
            (p: PolicyModel) =>
              !p.policyBasicDetail.isDeleted ||
              (p.policyBasicDetail.isDeleted &&
                this.clientUser!.affinityPartnerPolicyScheduleIds!.findIndex(
                  (a) => a === p.policyBasicDetail.policyId
                ) !== -1)
          )
        : this.policies.filter((p: PolicyModel) => !p.policyBasicDetail.isDeleted)
    this.policyCheckboxes = this.policyList.map((p) => {
      return {
        checked: false,
      }
    })
    this.selectedPoliciesField.forEach((policy) => {
      this.policyCheckboxes[this.policyList.indexOf(policy)].checked =
        this.policyList.indexOf(policy) !== -1 ? true : false
    })
  }

  private get isAffinityPartner() {
    return this.affinityPartner
  }

  private set isAffinityPartner(value: boolean) {
    if (this.clientUser) {
      this.clientUser.isAffinityPartner = !this.clientUser.isAffinityPartner
      this.affinityPartner = this.clientUser.isAffinityPartner
    }
  }

  private get selectedPolicies() {
    return this.selectedPoliciesField
  }

  private set selectedPolicies(value) {
    if (this.clientUser) {
      if (!value || !value.length) {
        this.clientUser.affinityPartnerPolicyScheduleIds = undefined
        this.selectedPoliciesField = []
      } else {
        this.clientUser.affinityPartnerPolicyScheduleIds = value.map((p) => p.policyBasicDetail.policyId)
        this.selectedPoliciesField = value
      }
    }
  }

  private get clientUser(): ClientUserDetailModel | null {
    return Object.assign({}, this.clientUserItem)
  }

  private Close() {
    this.$validator.errors.items = []
    this.$emit('OnDialogClose')
  }

  private async Save() {
    try {
      const result = await this.validate()
      if (result) {
        this.isLoading = true
        if (!this.isAffinityPartner) {
          this.selectedPolicies = []
        }
        const res = await ClientUserController.SaveClientUser(this.clientUser)
        if (typeof res !== 'string') {
          let clientUser: ClientUserDetailModel = new ClientUserDetailModel()
          clientUser = Object.assign({}, res)
          await this.saveProfilePicture(clientUser)
          this.$emit('saveClientUser', clientUser)
        } else {
          this.errorMsg = res
        }
        this.isLoading = false
      }
    } catch (err: any) {
      eventBus.$emit('errorHandler', 'Error saving client user detail, please try again', true)
      this.isLoading = false
      if (err.response?.statusText) {
        this.errorMsg = err.response.statusText
      }
    }
  }

  private async validate(): Promise<boolean> {
    let result = true
    const clientUserValidation: boolean = await this.$validator.validateAll('frmClientUser')

    // set focus to non validate field
    if (!clientUserValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
      result = clientUserValidation
    }

    return result
  }

  private async saveProfilePicture(clientUser: ClientUserDetailModel) {
    if (!this.profilePictureChanged) {
      return
    }
    try {
      if (!this.clientUser?.id) {
        throw new Error('Unable to save profile picture without an client user ID')
      }
      if (this.newProfilePicture) {
        const pp = await ClientUserController.setProfilePicture(this.clientUser.id, this.newProfilePicture)
        clientUser.profileImageUrl = pp.profilePictureUrl
        clientUser.profileImageThumbnailUrl = pp.profilePictureThumbnailUrl
      } else {
        ClientUserController.deleteProfilePicture(this.clientUser.id)
        clientUser.profileImageUrl = undefined
        clientUser.profileImageThumbnailUrl = undefined
      }
    } catch (err) {
      console.error(err)
      eventBus.$emit('errorHandler', 'Error saving profile picture', true)
    }
  }

  private onPolicySelectionChange(selectedItems: any, items: any) {
    if (selectedItems.length < items.length) {
      const selectedItem = this.policyList.find(
        (p) => p.policyBasicDetail.policyId === items[items.length - 1].policyBasicDetail.policyId
      )
      if (selectedItem !== undefined) {
        this.policyCheckboxes[this.policyList.indexOf(selectedItem)].checked = true
      }
    } else if (selectedItems.length > items.length) {
      const itemToRemoveFromist = selectedItems.find((e) => !items.includes(e))
      const item = this.policyList.find(
        (p) => p.policyBasicDetail.policyId === itemToRemoveFromist.policyBasicDetail.policyId
      )
      if (item !== undefined) {
        this.policyCheckboxes[this.policyList.indexOf(item)].checked = false
      }
    }
  }

  public onProfilePictureChanged(profilePicture: File | null) {
    this.profilePictureChanged = true
    this.newProfilePicture = profilePicture
    if (!profilePicture && this.clientUser) {
      this.clientUser.profileImageUrl = undefined
      this.clientUser.profileImageThumbnailUrl = undefined
    }
  }
}
</script>

<style scoped>
.common-error-message {
  color: #ff5252 !important;
}

.profile-banner {
  background-image: linear-gradient(#607d8c 50%, transparent 50%);
}
</style>
