var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.clientUser
    ? _c(
        "v-card",
        [
          _c(
            "v-layout",
            {
              staticClass: "profile-banner pt-3",
              attrs: { "align-center": "", "flex-col": "" },
            },
            [
              _c("ProfileImage", {
                attrs: { image: _vm.clientUser.profileImageUrl },
                on: { "update:image": _vm.onProfilePictureChanged },
              }),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-3 scroll-content-dialog" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "First Name",
                          maxlength: "50",
                          required: "",
                          "data-vv-scope": "frmClientUser",
                          "data-vv-name": "First Name",
                          "error-messages": _vm.errors.collect("First Name"),
                          name: "firstName",
                        },
                        model: {
                          value: _vm.clientUser.firstName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.clientUser,
                              "firstName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "clientUser.firstName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Last Name",
                          maxlength: "50",
                          required: "",
                          "data-vv-scope": "frmClientUser",
                          "data-vv-name": "Last Name",
                          "error-messages": _vm.errors.collect("Last Name"),
                          name: "lastName",
                        },
                        model: {
                          value: _vm.clientUser.lastName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.clientUser,
                              "lastName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "clientUser.lastName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Email",
                          maxlength: "50",
                          required: "",
                          "data-vv-scope": "frmClientUser",
                          "data-vv-name": "Email",
                          "error-messages": _vm.errors.collect("Email"),
                          name: "email",
                        },
                        model: {
                          value: _vm.clientUser.email,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.clientUser,
                              "email",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "clientUser.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "primary",
                          small: "",
                          label: "Ability to use Portal",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.clientUser.hasPortalAccess,
                          callback: function ($$v) {
                            _vm.$set(_vm.clientUser, "hasPortalAccess", $$v)
                          },
                          expression: "clientUser.hasPortalAccess",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "pt-3": "" } },
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "primary",
                                  small: "",
                                  label: "Affinity Partner",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.isAffinityPartner,
                                  callback: function ($$v) {
                                    _vm.isAffinityPartner = $$v
                                  },
                                  expression: "isAffinityPartner",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c("v-autocomplete", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isAffinityPartner,
                                    expression: "isAffinityPartner",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required policy-list",
                                attrs: {
                                  label: "Search policy",
                                  maxlength: "100",
                                  required: "",
                                  multiple: "",
                                  "return-object": "",
                                  items: _vm.policyList,
                                  loading: _vm.policySearchRunning,
                                  "item-text": "policyBasicDetail.name",
                                  "item-value": "policyBasicDetail.policyId",
                                  "data-vv-name": "Search policy",
                                  "error-messages":
                                    _vm.errors.collect("Search policy"),
                                  disabled: !_vm.isAffinityPartner,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onPolicySelectionChange(
                                      _vm.selectedPolicies,
                                      $event
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-action",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: { color: "primary" },
                                                    model: {
                                                      value:
                                                        _vm.policyCheckboxes[
                                                          _vm.policyList.indexOf(
                                                            data.item
                                                          )
                                                        ].checked,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.policyCheckboxes[
                                                            _vm.policyList.indexOf(
                                                              data.item
                                                            )
                                                          ],
                                                          "checked",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "policyCheckboxes[policyList.indexOf(data.item)].checked",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", {
                                                    class: data.item
                                                      .policyBasicDetail
                                                      .isDeleted
                                                      ? "deleted-item"
                                                      : "",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        data.item
                                                          .policyBasicDetail
                                                          .name
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  796266564
                                ),
                                model: {
                                  value: _vm.selectedPolicies,
                                  callback: function ($$v) {
                                    _vm.selectedPolicies = $$v
                                  },
                                  expression: "selectedPolicies",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errorMsg,
                      expression: "errorMsg",
                    },
                  ],
                  attrs: { xs12: "" },
                },
                [
                  _c("div", { staticClass: "pt-3 common-error-message" }, [
                    _c("div", { staticClass: "red pa-2 d-block lighten-4" }, [
                      _c("span", [_c("b", [_vm._v(_vm._s(_vm.errorMsg))])]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.Close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 submit-btn",
                  attrs: {
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                    color: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.Save.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" submit ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }