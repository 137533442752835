<template>
  <div>
    <div v-if="worker.activityName !== 'DisplayOfflineUsers'" class="dashboard-worker" :class="dynamicClass">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span :class="worker.activityName === 'Busy' ? 'oncall-person' : ''" v-on="on">
            <img :src="imagePath" :alt="worker.friendlyName" />
            <span v-if="worker.activityName === 'Busy'" class="call-icon">
              <v-icon>call</v-icon>
            </span>
          </span>
        </template>
        <span>{{ worker.friendlyName }}</span>
      </v-tooltip>
    </div>
    <div
      v-if="!showAllWorker && worker.activityName === 'DisplayOfflineUsers'"
      class="teaminfo b-secondory"
      @click="showOfflineWorkers"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span class="offline-user" v-on="on">
            <span class="showoffline-user text-xs-center">
              <v-icon>person_add</v-icon>
            </span>
          </span>
        </template>
        <span>Show Offline Users</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { WorkerWithAdditions } from '@/components/dashboard/worker'
import UserModel from '@/models/user/UserModel'
import StoreGetters from '@/storeGetters'

@Component({
  name: 'DashboardWorker',
})
export default class DashboardWorker extends Vue {
  @Prop({ required: true })
  public worker: WorkerWithAdditions
  @Prop() public showAllWorker: boolean

  public get workerOnline(): boolean {
    return this.worker.activityName !== 'Offline'
  }

  public get dynamicClass(): Record<string, boolean> {
    let statusOutline: string
    if (this.worker.missedCalls >= 2) {
      statusOutline = 'status-outline-critical'
    } else if (this.worker.missedCalls >= 1) {
      statusOutline = 'status-outline-warning'
    } else {
      statusOutline = 'status-outline-normal'
    }

    return {
      [statusOutline]: true,
      offline: !this.workerOnline,
    }
  }

  public get users(): UserModel[] {
    return StoreGetters.getUsers()
  }

  public get imagePath(): string {
    if (this.worker.attributes.userId != null) {
      const user = this.users.find((e) => e.id === this.worker.attributes.userId)
      if (user) {
        return user.profileThumbnailUrlWithUnknownFallback
      }
    }
    return '/img/unknownuser.png'
  }

  private showOfflineWorkers() {
    this.$emit('showOfflineWorkers')
  }
}
</script>

<style scoped>
.dashboard-worker {
  display: inline-block;
  height: 53px;
  width: 53px;
}

.dashboard-worker img {
  max-width: 100%;
  border: 3px solid;
  border-radius: 50%;
  width: 100%;
}

.status-outline-normal img {
  border-color: #4caf50;
}

.status-outline-critical img {
  border-color: #f44336;
}

.status-outline-warning img {
  border-color: #ff9800;
}

.dashboard-worker.offline img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.oncall-person {
  position: relative;
  display: inline-block;
}

.oncall-person .call-icon {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 5px;
  top: 0px;
  display: inline-block;
  text-align: center;
  background-color: rgba(76, 175, 80, 0.7);
  border-radius: 50%;
  color: #fff;
  padding: 18px 0px;
}

.oncall-person .call-icon i {
  color: #fff;
}

.call-badge-content {
  position: relative;
  display: inline-block;
}

.teaminfo {
  display: inline-block;
  max-height: 55px;
  max-width: 55px;
}

.offline-user {
  display: inline-block;
}

.offline-user .showoffline-user {
  height: 53px;
  width: 53px;
  display: inline-block;
  background-color: rgba(199, 199, 199, 0.5);
  border-radius: 50%;
  border: 2px solid #939393;
  top: -6px;
  padding: 10px 0px;
  cursor: pointer;
  transition: 0.3s all linear;
}

.offline-user .showoffline-user:hover {
  border: 2px solid #565555;
}
.offline-user .showoffline-user i {
  font-size: 30px;
}

.offline-user .showoffline-user .count {
  font-size: 22px;
  font-weight: 600;
  color: #7b7979;
  display: inline-block;
  margin-top: 5px;
  cursor: pointer;
}
</style>
