import RelativeUserPermissionsModel from '@/models/users/relative-user-permissions-model'
import UserDetailModel from '@/models/users/user-detail-model'
import UserListItemModel from '@/models/users/user-list-item-model'
import UserPermissionsModel from '@/models/users/user-permissions-model'
import UserRoleListItemModel from '@/models/users/user-role-list-item-model'
import UserTeamListItemModel from '@/models/users/user-team-list-item-model'
import IUsersState from './interfaces/users-state'

export default class UsersState implements IUsersState {
  public showDeletedUsers = false
  public users: UserListItemModel[] = []
  public teams: UserTeamListItemModel[] = []
  public roles: UserRoleListItemModel[] = []
  public permissions: UserPermissionsModel = new UserPermissionsModel()
  public loadingUsers = false
  public loadingTeams = false
  public loadingRoles = false
  public loadingPermissions = false
  public selectedUser: UserDetailModel | null = null
  public relativeUserPermissions: RelativeUserPermissionsModel | null = null
  public selectedUserProfilePictureChanged = false
  public selectedUserNewProfilePicture: File | null = null
}
