<template>
  <div>
    <v-layout wrap pa-3>
      <v-flex>
        <v-select
          v-model="jobRejectReason"
          v-validate="'required'"
          :items="jobRejectReasonList"
          item-text="description"
          item-value="description"
          label="Select reason for rejecting job"
          required
          class="required"
          :loading="jobRejectReasonListLoading"
          data-vv-scope="rejectJobValidator"
          data-vv-name="Cancel Job Reason"
          :error-messages="errors.collect('Cancel Job Reason')"
        ></v-select>
      </v-flex>
      <v-flex xs12>
        <v-textarea v-model="jobRejectDescription" label="Description"></v-textarea>
      </v-flex>
    </v-layout>

    <v-layout class="justify-end">
      <v-flex xs4 class="d-flex justify-end">
        <v-btn class="primary" :loading="isRejectJobLoading" :disabled="isRejectJobLoading" @click="rejectJob">
          Submit
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import DeploymentController from '@/api/deployment-controller'
import MasterRecordController from '@/api/masterRecordController'
import { RejectJobOfferRequest } from '@/api/models/deployment/reject-job-offer-request'
import { RecordType } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import { useDeploymentStore } from '@/pinia/deployment'
import { storeToRefs } from 'pinia'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class JobRejectionReason extends Vue {
  public jobRejectReason = ''
  public jobRejectDescription = ''
  public jobRejectReasonList: MasterRecord[] = []
  public jobRejectReasonListLoading = false
  public isRejectJobLoading = false

  private async mounted() {
    await this.getCancelJobReasonList()
  }

  private async getCancelJobReasonList(): Promise<void> {
    this.jobRejectReasonListLoading = true
    this.jobRejectReason = ''
    this.jobRejectDescription = ''

    const records = await MasterRecordController.GetMasterRecords(RecordType[RecordType.ContractorUnavailableReason])

    if (records) {
      this.jobRejectReasonList = records.filter((e) => e.isDeleted === false)
    }

    this.jobRejectReasonListLoading = false
  }

  public async rejectJob() {
    const { jobOffer } = storeToRefs(useDeploymentStore())
    const result: boolean = await this.$validator.validateAll('rejectJobValidator')

    if (result && jobOffer.value) {
      this.isRejectJobLoading = true
      const request: RejectJobOfferRequest = {
        reasonType: this.jobRejectReason,
        reasonDescription: this.jobRejectDescription,
        jobId: jobOffer.value.contractorAppointedModel.jobId,
        emergencyId: jobOffer.value.contractorAppointedModel.emergencyId,
        legacyAppointmentId: jobOffer.value.contractorAppointedModel.id,
        deploymentId: jobOffer.value.deploymentId,
        appointmentRequestId: jobOffer.value.appointmentRequestId,
        contractorId: jobOffer.value.contractorAppointedModel.contractorId,
      }

      const displayError = () =>
        this.$store.dispatch(
          'snackbarModule/showSnackbar',
          new ShowErrorSnackbar('Failed to reject job, please try again or contact support', 0)
        )

      try {
        const result = await DeploymentController.RejectJobOffer(request)
        if (result?.isApproved) {
          this.$emit('jobRejected')
        } else {
          displayError()
        }
      } catch {
        displayError()
      }
      this.isRejectJobLoading = false
    }
  }
}
</script>
