var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "bar",
          rawName: "v-bar",
          value: { useScrollbarPseudo: true },
          expression: "{ useScrollbarPseudo: true }",
        },
      ],
    },
    [
      _vm.showClientUserDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showClientUserDialog,
                callback: function ($$v) {
                  _vm.showClientUserDialog = $$v
                },
                expression: "showClientUserDialog",
              },
            },
            [
              _c("ClientUser", {
                ref: "clientUser",
                attrs: {
                  policies: _vm.policies,
                  "policy-search-running": _vm.policySearchRunning,
                  "client-user-item": _vm.clientUser,
                },
                on: {
                  saveClientUser: _vm.onSaveClientUser,
                  OnDialogClose: _vm.closeDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0", attrs: { "primary-title": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs2: "" } },
                [
                  _c("v-select", {
                    attrs: { items: ["Client Users", "Affinity Partners"] },
                    model: {
                      value: _vm.selectedUserType,
                      callback: function ($$v) {
                        _vm.selectedUserType = $$v
                      },
                      expression: "selectedUserType",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "mt-0 pt-0",
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              !_vm.isUserRoleClientManager
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "mx-0 add-action-btn add-client-user",
                                      attrs: { icon: "" },
                                      on: { click: _vm.onCreateClientUser },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { color: "grey" } }, [
                                      _vm._v("add"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1303432445
                      ),
                    },
                    [_c("span", [_vm._v("Add Client User")])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "gridView clientuserlist",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.getClientUsers,
                  loading: _vm.isLoading,
                  search: _vm.search,
                  pagination: _vm.pagination,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", { staticClass: "profile-picture" }, [
                          _c("img", {
                            attrs: {
                              src:
                                props.item.profileImageThumbnailUrl ||
                                "/img/unknownuser.png",
                            },
                          }),
                        ]),
                        _c("td", [_vm._v(_vm._s(props.item.firstName))]),
                        _c("td", [_vm._v(_vm._s(props.item.lastName))]),
                        _c("td", [_vm._v(_vm._s(props.item.email))]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.hasPortalAccess ? "Yes" : "No"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "justify-center layout px-0" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mx-0 edit-btn",
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    _vm.isUserRoleClientManager,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEditClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("edit")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Edit")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "mx-0 mt-2 ml-2 delete-btn",
                                                attrs: {
                                                  icon: "",
                                                  disabled:
                                                    _vm.isUserRoleClientManager,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDeleteClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Delete")])]
                            ),
                            _c(
                              "v-snackbar",
                              {
                                attrs: {
                                  timeout: _vm.snackbarTimeout,
                                  left: "",
                                  bottom: "",
                                },
                                model: {
                                  value: _vm.snackbar,
                                  callback: function ($$v) {
                                    _vm.snackbar = $$v
                                  },
                                  expression: "snackbar",
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      dark: "",
                                      flat: "",
                                      color: "secondary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.snackbar = false
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }