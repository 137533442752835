<template>
  <v-layout flex-col align-center gap-2>
    <div class="wrapper">
      <img :src="profilePicture" class="image rounded-full elevation-1" :class="size" />

      <v-layout flex-col justify-space-between class="buttons">
        <v-btn label icon color="secondary" class="ma-0">
          <label class="pa-2 cursor-pointer">
            <v-icon>edit</v-icon>

            <input
              ref="image"
              v-validate="{
                rules: {
                  required: false,
                  image: true,
                  size: profileImageSizeKb,
                  mimes: ['image/jpeg', 'image/jpg', 'image/png'],
                  ext: validExtensions,
                },
              }"
              type="file"
              class="hide"
              accept="image/*"
              name="picture"
              data-vv-scope="profileImage"
              @change="onImageChanged"
            />
          </label>
        </v-btn>

        <v-btn v-if="image" icon color="error" class="ma-0 pa-2" @click.native.stop="clear">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-layout>
    </div>

    <div class="error--text">
      {{ validationMessage(errors) }}
    </div>
  </v-layout>
</template>

<script lang="ts">
import { ErrorBag } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ProfileImage extends Vue {
  @Prop({ default: '' }) public image: string
  @Prop({ default: 'medium' }) public size: string

  public localImageData: string | ArrayBuffer | null = null

  public noImageImage = '/img/unknownuser.png'
  public validExtensions = ['jpg', 'jpeg', 'png']

  public get profileImageSizeKb() {
    return parseInt(process.env.VUE_APP_MaximumProfilePictureSizeKb ?? '1024')
  }

  public get profilePicture() {
    return this.localImageData || this.image || this.noImageImage
  }

  public clear() {
    this.localImageData = null
    this.$emit('update:image', null)
  }

  public validationMessage(err: ErrorBag) {
    if (err.collect('picture').length > 0) {
      const message: string = err.collect('picture')[0]
      this.clear()
      return message === 'The picture field must be an image.' ? 'Only JPEG, JPG, PNG files are allowed.' : message
    }
  }

  public onImageChanged(event: Event) {
    const target = event.target as HTMLInputElement
    if (!target) {
      return
    }

    const file = (target.files || [])[0] as File | undefined
    if (!file || file.size > this.profileImageSizeKb * 1024) {
      return
    }
    const ext = (file.name.split('.').at(-1) || '').toLowerCase()
    if (!this.validExtensions.includes(ext)) {
      return
    }

    const fr = new FileReader()
    fr.addEventListener('load', () => (this.localImageData = fr.result))
    fr.readAsDataURL(file)
    this.$emit('update:image', file)
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
  display: flex;
}

.image {
  background-color: #fff;
  object-fit: cover;
  border-style: solid;
  border-color: white;
}

.image.medium {
  border-width: 0.5rem;
  height: 150px;
  width: 150px;
}

.image.small {
  border-width: 0.25rem;
  height: 85px;
  width: 85px;
}

.buttons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(0.9);
  transition: all var(--transition-duration);
}

*:hover > .buttons {
  opacity: 1;
  transform: none;
}
</style>
