var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "close-on-content-click": false,
            "nudge-width": 250,
            "max-width": 250,
            "z-index": 999,
            absolute: "",
            left: "",
            "content-class": "dialpad-box",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class:
                          _vm.twilio.workerInQueue || _vm.callStart
                            ? ""
                            : "offQueue",
                        attrs: { icon: "", large: "", flat: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.dialpadClick.apply(null, arguments)
                          },
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            overlap: "",
                            color: _vm.callStart ? "green" : "secondary",
                          },
                        },
                        [
                          !_vm.twilio.workerInQueue && _vm.callStart
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { slot: "badge", small: "" },
                                  slot: "badge",
                                },
                                [_vm._v("call")]
                              )
                            : _vm.twilio.workerInQueue
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { slot: "badge", small: "" },
                                  slot: "badge",
                                },
                                [_vm._v("call_end")]
                              )
                            : _vm._e(),
                          _c(
                            "v-avatar",
                            { attrs: { flat: "", size: 34 } },
                            [
                              _vm.twilio.workerInQueue
                                ? _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("dialpad"),
                                  ])
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    { attrs: { dark: "" } },
                                                    on
                                                  ),
                                                  [_vm._v("dialpad")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.twilio
                                                .workerNotInQueueReason ??
                                                "Not Connected"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "line-arrow secondary" }),
                      _c("span", { staticClass: "n-arrow" }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showDialpad,
            callback: function ($$v) {
              _vm.showDialpad = $$v
            },
            expression: "showDialpad",
          },
        },
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.twilio.workerInWrapUp,
                  expression: "!twilio.workerInWrapUp",
                },
              ],
              staticClass: "dialpad",
            },
            [
              _c(
                "v-container",
                {
                  class: _vm.callStart
                    ? "call-started"
                    : "" && _vm.showDialpadInCall
                    ? " show-dialpad"
                    : "",
                  attrs: { "py-2": "", "px-0": "", fluid: "" },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "text-xs-center": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showNumberPad,
                              expression: "showNumberPad",
                            },
                          ],
                          attrs: { xs12: "" },
                        },
                        [
                          _c("PhoneNumber", {
                            staticClass: "twilio-phonenumber",
                            attrs: {
                              "passed-phone-number": _vm.phoneNumber,
                              "add-digits": _vm.addDigits,
                            },
                            on: {
                              phoneNumberUpdated: _vm.phoneNumberUpdated,
                              "digits-added": function ($event) {
                                _vm.addDigits = ""
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showNumberPad,
                              expression: "!showNumberPad",
                            },
                          ],
                          attrs: { xs12: "" },
                        },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        ref: "contactList",
                                        staticClass: "contactList pt-0",
                                        attrs: {
                                          items: _vm.twilio.workspaceContacts,
                                          "return-object": "",
                                          "single-line": "",
                                          "hide-details": "",
                                          "item-text": "name",
                                          "item-value": "contactUri",
                                          clearable: "",
                                          autofocus: "",
                                        },
                                        on: { change: _vm.onContactSelect },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-tile-content",
                                                  {
                                                    staticClass:
                                                      "select-contact",
                                                  },
                                                  [
                                                    data.item
                                                      ? _c(
                                                          "v-list-tile-title",
                                                          {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  data.item.name
                                                                ),
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-tile",
                                                  {
                                                    staticClass:
                                                      "contactlist-member",
                                                  },
                                                  [
                                                    _c("v-list-tile-avatar", [
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            "teaminfo " +
                                                            _vm.getClassName(
                                                              data.item
                                                            ),
                                                        },
                                                        [
                                                          data.item
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: _vm.getContactImage(
                                                                    data.item
                                                                      .userId
                                                                  ),
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c(
                                                      "v-list-tile-content",
                                                      [
                                                        data.item
                                                          ? _c(
                                                              "v-list-tile-title",
                                                              {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      data.item
                                                                        .name
                                                                    ),
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        data.item
                                                          ? _c(
                                                              "v-list-tile-sub-title",
                                                              {
                                                                domProps: {
                                                                  textContent:
                                                                    _vm._s(
                                                                      data.item
                                                                        .teamName
                                                                    ),
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.selectedContact,
                                          callback: function ($$v) {
                                            _vm.selectedContact = $$v
                                          },
                                          expression: "selectedContact",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [_c("v-divider")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "text-xs-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("1")
                                },
                              },
                            },
                            [_vm._v("1")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("2")
                                },
                              },
                            },
                            [_vm._v("2")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("3")
                                },
                              },
                            },
                            [_vm._v("3")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("4")
                                },
                              },
                            },
                            [_vm._v("4")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("5")
                                },
                              },
                            },
                            [_vm._v("5")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("6")
                                },
                              },
                            },
                            [_vm._v("6")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("7")
                                },
                              },
                            },
                            [_vm._v("7")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("8")
                                },
                              },
                            },
                            [_vm._v("8")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("9")
                                },
                              },
                            },
                            [_vm._v("9")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("*")
                                },
                              },
                            },
                            [_vm._v("*")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "mouse-hold",
                                  rawName: "v-mouse-hold",
                                  value: _vm.zeroHoldConfig,
                                  expression: "zeroHoldConfig",
                                },
                              ],
                              staticClass: "dialpad-add-icon",
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit(_vm.showPlus ? "+" : "0")
                                },
                              },
                            },
                            [
                              _vm.showPlus
                                ? [_vm._v("+")]
                                : [
                                    _vm._v(" 0 "),
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("add"),
                                    ]),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", large: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendDigit("#")
                                },
                              },
                            },
                            [_vm._v("#")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _vm.showDialpadInCall
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    large: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      _vm.showDialpadInCall = false
                                                      _vm.callStart = true
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("arrow_back"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    147926325
                                  ),
                                },
                                [_c("span", [_vm._v("Back to call")])]
                              )
                            : _vm._e(),
                          _vm.callForward && !_vm.transferCallSid
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "green white--text",
                                                  attrs: {
                                                    icon: "",
                                                    large: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.backToOngoingCall.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/icons/call/return-to-call.svg"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4086116904
                                  ),
                                },
                                [_c("span", [_vm._v("Return to Call")])]
                              )
                            : _vm._e(),
                          _vm.callForward && _vm.transferCallSid
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "green white--text",
                                                  attrs: {
                                                    icon: "",
                                                    large: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.endForwardCall.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/icons/call/return-to-call.svg"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2451892325
                                  ),
                                },
                                [_c("span", [_vm._v("Return to Call")])]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.callForward,
                                  expression: "!callForward",
                                },
                              ],
                              class:
                                (!_vm.phoneNumber && !_vm.selectedContact) ||
                                _vm.showDialpadInCall
                                  ? "grey"
                                  : "green" + " white--text",
                              attrs: {
                                icon: "",
                                large: "",
                                color:
                                  (!_vm.phoneNumber && !_vm.selectedContact) ||
                                  _vm.showDialpadInCall
                                    ? "grey"
                                    : "green" + " white--text",
                                disabled:
                                  (!_vm.phoneNumber && !_vm.selectedContact) ||
                                  _vm.showDialpadInCall ||
                                  _vm.makingCallInProgress,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.makeCall.apply(null, arguments)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("call")])],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.callForward && !_vm.transferCallSid,
                                  expression: "callForward && !transferCallSid",
                                },
                              ],
                              attrs: {
                                icon: "",
                                large: "",
                                color:
                                  !_vm.phoneNumber && !_vm.selectedContact
                                    ? "grey"
                                    : "blue" + " white--text",
                                disabled:
                                  (!_vm.phoneNumber && !_vm.selectedContact) ||
                                  _vm.callForwardingDisabled,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.makeForwardCall.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("call")])],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.callForward && _vm.transferCallSid,
                                  expression: "callForward && transferCallSid",
                                },
                              ],
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              color: "orange white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.backToOngoingCall.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/icons/call/warm-transfer.svg"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Warm Transfer")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.callForward && _vm.transferCallSid,
                                  expression: "callForward && transferCallSid",
                                },
                              ],
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "red",
                                            attrs: { icon: "", large: "" },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.coldTransfer.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/icons/call/cold-transfer.svg"),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Cold Transfer")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      staticClass: "call-counter",
                      attrs: { row: "", wrap: "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-xs-right": "" } },
                        [
                          _vm.showReturnToQueueButton
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    medium: "",
                                                    color: "green white--text",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.returnCallerToQueue.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/icons/call/return-to-queue.svg"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1035169374
                                  ),
                                },
                                [_c("span", [_vm._v("Return To Queue")])]
                              )
                            : _vm._e(),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              medium: "",
                                              color: "darkgrey white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                _vm.showDialpadInCall = true
                                                _vm.callStart = false
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("dialpad")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Dialpad")])]
                          ),
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "label-counter text-xs-center white--text",
                          },
                          [
                            _c("div", { staticClass: "mb-2 number" }, [
                              _vm._v(_vm._s(_vm.callingNumberLabel)),
                            ]),
                            _c("div", { staticClass: "mb-2" }, [
                              _vm._v(_vm._s(_vm.policyName)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "white", small: "" } },
                                  [_vm._v("timer")]
                                ),
                                _vm._v("   "),
                                _c("Counter", { ref: "twilioCallCounter" }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.callOnHold,
                                    expression: "callOnHold",
                                  },
                                ],
                                staticClass: "mb-2",
                              },
                              [_vm._v("On Hold")]
                            ),
                            _c("div", { staticClass: "mb-2" }, [
                              _vm._v("Dialling"),
                            ]),
                          ]
                        ),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-xs-center": "" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              color: "red white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.callEnd.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("call_end")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("End Call")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              color: "orange white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.holdButtonClicked.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.callOnHold,
                                                  expression: "!callOnHold",
                                                },
                                              ],
                                            },
                                            [_vm._v("pause_circle_outline")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.callOnHold,
                                                  expression: "callOnHold",
                                                },
                                              ],
                                            },
                                            [_vm._v("pause_circle_filled")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Hold")])]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            class: _vm.callMuted
                                              ? "text--lighten-1"
                                              : "darken-2 text--lighten-3",
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              color: "grey white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.muteButtonClicked.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("keyboard_voice"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.callMuted ? "Unmute" : "Mute")
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              large: "",
                                              color: "blue white--text",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.forwardButtonClicked.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("phone_forwarded"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Forward")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.twilio.workerInWrapUp,
                  expression: "twilio.workerInWrapUp",
                },
              ],
              attrs: { absolute: "", top: "", right: "" },
            },
            [
              _c(
                "v-container",
                { attrs: { "py-2": "", "px-0": "", fluid: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "", "text-xs-left": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-2": "", "px-3": "" } },
                        [
                          _c(
                            "b",
                            { staticClass: "secondary--text" },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "secondary", small: "" } },
                                [_vm._v("timer")]
                              ),
                              _c("span", { staticClass: "countdown" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.twilio.workerWrapUpCountdown) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("span", [_vm._v("Wrap-up time remaining")]),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "text-xs-center": "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "success" },
                              on: { click: _vm.returnToQueue },
                            },
                            [
                              _c("v-icon", [_vm._v("group_add")]),
                              _vm._v("   Return to Queue "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("IncomingCallTaskAlert", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showIncomingCallAlert,
            expression: "showIncomingCallAlert",
          },
        ],
        attrs: { "call-task-alert": _vm.callTaskAlert },
        on: {
          answerCall: _vm.answerCall,
          initiateCallback: _vm.initiateCallback,
        },
      }),
      _vm.phonecallAssignDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "v-dialog--scrollable",
                "max-width": "650",
                persistent: "",
              },
              model: {
                value: _vm.phonecallAssignDialog,
                callback: function ($$v) {
                  _vm.phonecallAssignDialog = $$v
                },
                expression: "phonecallAssignDialog",
              },
            },
            [
              _c("PhoneCallJobsAssign", {
                attrs: {
                  "job-item-list": _vm.jobList,
                  "unlinked-phone-call": _vm.unlinkedPhoneCallDetail,
                  "is-data-loaded": _vm.isDataLoaded,
                  "is-via-twillio": true,
                },
                on: {
                  onJobAssignClose: _vm.closeJobAssignDialog,
                  linkPhoneCallWithJob: _vm.updateCallDetail,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }