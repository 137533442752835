<template>
  <v-dialog v-model="showDialog" max-width="850" scrollable>
    <v-card class="elevation-0">
      <EditUser />
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!isValid" @click="save()">Save</v-btn>
        <v-btn @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import ShowWarningSnackbar from '@/models/snackbar/show-warning-snackbar'
import UpdateUserResponseModel from '@/models/users/update-user-response-model'
import { AxiosError } from 'axios'
import { Component, Provide, Vue } from 'vue-property-decorator'
import EditUser from './EditUser.vue'

@Component({
  components: { EditUser },
})
export default class EditUserDialog extends Vue {
  @Provide('validator') public validator = this.$validator

  public get isValid(): boolean {
    return !this.$validator.errors.any()
  }

  public get showDialog(): boolean {
    return this.$store.getters['usersModule/showEditDialog']
  }

  public set showDialog(value: boolean) {
    if (!value) {
      this.cancel()
    }
  }

  public get isNew(): boolean {
    return this.$store.getters['usersModule/creatingUser']
  }

  public async save(): Promise<void> {
    // Button should be disabled if this is false, but check here anyway.
    try {
      if (this.isValid) {
        const result = (await this.$store.dispatch('usersModule/updateUser')) as UpdateUserResponseModel
        if (result.passwordReset) {
          this.$store.dispatch(
            'snackbarModule/showSnackbar',
            new ShowWarningSnackbar(
              "The user's password has been reset. Please let them know before they attempt to log in again.",
              0,
              true
            )
          )
        }
      }
    } catch (err) {
      const axError = err as AxiosError
      const data: object = axError && axError.response ? axError.response.data || {} : {}
      const errMessage = data['Message'] || 'Unknown Error'
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar(`Error updating user: ${errMessage}`))
    }
  }

  public cancel(): void {
    this.$store.dispatch('usersModule/clearSelectedUser')
  }
}
</script>

<style lang="scss" scoped>
.v-card {
  overflow-y: auto !important;
  max-height: 80vh !important;

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);

    &-track {
      background: white;
    }

    &-thumb {
      background: #c7c7c7;
    }
  }
}
</style>
