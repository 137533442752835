var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        attrs: {
          label: "Set Payment Terms",
          items: _vm.paymentTermOptions,
          "data-vv-scope": "sendInvoiceValidator",
          required: "",
          "data-vv-name": "Payment Terms",
          "error-messages": _vm.errors.collect("Payment Terms"),
        },
        model: {
          value: _vm.selectedPaymentTerm,
          callback: function ($$v) {
            _vm.selectedPaymentTerm = $$v
          },
          expression: "selectedPaymentTerm",
        },
      }),
      _c("p", { staticClass: "text-left font-weight-bold mt-3" }, [
        _vm._v("Payment Script"),
      ]),
      _c("p", { staticClass: "text-left font-italic mb-3" }, [
        _vm._v(
          "As we have been unable to take a card payment, I will now issue an invoice to the email address you have provided. The invoice can be paid securely using a debit or credit card, Apple Pay, or Google Pay. Please note that we may not be able to proceed with the scheduled work until the payment has been received. Are you happy for me to issue this invoice?"
        ),
      ]),
      _c("v-checkbox", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "required prepend-label",
        attrs: {
          "data-vv-scope": "sendInvoiceValidator",
          required: "",
          "data-vv-name": "Confirmation",
          "error-messages": _vm.errors.collect("Confirmation"),
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "invoice-text" }, [
                  _vm._v(
                    " I confirm that I have read the above to the customer and have been authorized to send the invoice via email "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-layout",
        { staticClass: "justify-end mt-2" },
        [
          _c(
            "v-flex",
            { staticClass: "d-flex justify-end", attrs: { xs4: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancelInvoice")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: { color: _vm.brandColour },
                  on: { click: _vm.onSendInvoicePress },
                },
                [_vm._v("Send Invoice")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }