var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [_vm.getClassName, _vm.openJobView ? "show-jobs" : ""],
      attrs: { id: "app" },
    },
    [
      _vm.isShowOverlayImage
        ? _c("div", { staticClass: "overlay-images" })
        : _vm._e(),
      _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _vm.isOnline
            ? _c(
                "div",
                [
                  !_vm.jobIdToShowInHeader
                    ? _c(
                        "v-navigation-drawer",
                        {
                          staticClass: "side-menubar",
                          attrs: {
                            fixed: "",
                            clipped: _vm.$vuetify.breakpoint.mdAndUp,
                            app: "",
                            temporary: "",
                          },
                          model: {
                            value: _vm.showNavDrawer,
                            callback: function ($$v) {
                              _vm.showNavDrawer = $$v
                            },
                            expression: "showNavDrawer",
                          },
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _vm.isLoggedIn && _vm.showMenuIcon
                                ? [
                                    _c("SidedrawMenu", {
                                      on: { menuItemClick: _vm.setNavDrawerl },
                                    }),
                                  ]
                                : _vm._e(),
                              _vm.isLoggedIn ? _c("v-divider") : _vm._e(),
                              _vm.isLoggedIn
                                ? _c(
                                    "v-list-tile",
                                    { on: { click: _vm.logout } },
                                    [
                                      _c(
                                        "v-list-tile-action",
                                        [_c("v-icon", [_vm._v("exit_to_app")])],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v("Logout"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "top-menubar",
                      attrs: {
                        app: "",
                        "clipped-left": _vm.$vuetify.breakpoint.mdAndUp,
                        fixed: "",
                        color: _vm.isOffline ? "grey darken-2" : "primary",
                        dark: "",
                      },
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "ml-0" },
                        [
                          _vm.isLoggedIn &&
                          _vm.showMenuIcon &&
                          !_vm.isClientUserLoggedIn &&
                          !_vm.jobIdToShowInHeader
                            ? _c("v-toolbar-side-icon", {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setNavDrawerl.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.showWarning && _vm.jobId
                            ? _c("JobWarnings", {
                                attrs: { "job-id": _vm.jobId },
                              })
                            : _vm._e(),
                          _vm.showJobAddress
                            ? _c(
                                "span",
                                { staticClass: "hidden-sm-and-down" },
                                [_vm._v(" " + _vm._s(_vm.jobAddress) + " ")]
                              )
                            : _vm.showDashBoardName !== ""
                            ? _c(
                                "span",
                                { staticClass: "hidden-sm-and-down" },
                                [
                                  !_vm.jobIdToShowInHeader
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.showDashBoardName)),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          "Job Id: " +
                                            _vm._s(_vm.jobIdToShowInHeader)
                                        ),
                                      ]),
                                ]
                              )
                            : _c(
                                "span",
                                { staticClass: "hidden-sm-and-down" },
                                [_vm._v("SIMPLIFi")]
                              ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.isLoggedIn && !_vm.jobIdToShowInHeader
                        ? _c("UniversalSearch", {
                            attrs: { "parent-component": "App" },
                          })
                        : _vm._e(),
                      _vm.isLoggedIn
                        ? _c("Notification", {
                            attrs: {
                              "notification-snackbar": _vm.notificationSnackbar,
                            },
                            on: {
                              "update:notificationSnackbar": function ($event) {
                                _vm.notificationSnackbar = $event
                              },
                              "update:notification-snackbar": function (
                                $event
                              ) {
                                _vm.notificationSnackbar = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.addSpace
                        ? _c("div", { staticClass: "menu-spacer" })
                        : _vm._e(),
                      _vm.dialpadV2Enabled &&
                      _vm.isLoggedIn &&
                      _vm.isUserLoggedIn
                        ? _c("TwilioClientV2", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showLiveLocation,
                                expression: "!showLiveLocation",
                              },
                            ],
                            on: {
                              showTwilioSnackbar: _vm.showTwilioSnackbar,
                              clearTwilioSnackbar: _vm.clearTwilioSnackbar,
                              handleStatusUpdate: _vm.handleStatusUpdate,
                            },
                          })
                        : _vm._e(),
                      !_vm.dialpadV2Enabled &&
                      _vm.isLoggedIn &&
                      _vm.isUserLoggedIn
                        ? _c("TwilioClient", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showLiveLocation,
                                expression: "!showLiveLocation",
                              },
                            ],
                            on: {
                              showTwilioSnackbar: _vm.showTwilioSnackbar,
                              clearTwilioSnackbar: _vm.clearTwilioSnackbar,
                              handleStatusUpdate: _vm.handleStatusUpdate,
                            },
                          })
                        : _vm._e(),
                      _vm.isLoggedIn ? _c("AppSwitcher") : _vm._e(),
                      _vm.isLoggedIn
                        ? _c("UserMenu", {
                            attrs: {
                              "image-snackbar": _vm.imageSnackbar,
                              "image-snackbar-text": _vm.imageSnackbarText,
                            },
                            on: {
                              "update:imageSnackbar": function ($event) {
                                _vm.imageSnackbar = $event
                              },
                              "update:image-snackbar": function ($event) {
                                _vm.imageSnackbar = $event
                              },
                              "update:imageSnackbarText": function ($event) {
                                _vm.imageSnackbarText = $event
                              },
                              "update:image-snackbar-text": function ($event) {
                                _vm.imageSnackbarText = $event
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userRoleDescription,
                    expression: "userRoleDescription",
                  },
                ],
                staticClass: "offline-header",
              }),
          _c(
            "v-content",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "fill-height": "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        {
                          class: _vm.isOffline ? "offline-card-wrap" : "",
                          attrs: { xs12: "" },
                        },
                        [
                          _vm.isOnline && !_vm.isAppSettingsError
                            ? _c(
                                "v-fade-transition",
                                {
                                  directives: [
                                    { name: "bar", rawName: "v-bar" },
                                  ],
                                  class: _vm.openJobView
                                    ? "hide-dashboard"
                                    : "show-dashboard",
                                  attrs: { mode: "out-in" },
                                },
                                [
                                  _c("router-view", {
                                    key: _vm.$route.fullPath,
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                "multi-line": true,
                                color: "red",
                                timeout: 0,
                                bottom: "",
                              },
                              model: {
                                value: _vm.isAppSettingsError,
                                callback: function ($$v) {
                                  _vm.isAppSettingsError = $$v
                                },
                                expression: "isAppSettingsError",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.appSettingsErrorMsg)),
                              ]),
                            ]
                          ),
                          _vm.isOffline
                            ? _c(
                                "v-card",
                                { staticClass: "elevation-0 offline-card" },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      staticClass:
                                        "d-flex justify-center align-center",
                                    },
                                    [
                                      _c("v-flex", [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/error-img.jpeg",
                                            alt: "cloud image",
                                          },
                                        }),
                                      ]),
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2",
                                          attrs: { "ml-5": "" },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "display-2 mb-4 font-weight-bold grey--text text--darken-2",
                                            },
                                            [_vm._v("No Internet")]
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              "The service is currently offline."
                                            ),
                                          ]),
                                          _c("p", [_vm._v("Try:")]),
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v(
                                                "Checking the network cables, modem, and router"
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v("Reconnecting to Wi-Fi"),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                "Reconnecting network diagnostics"
                                              ),
                                            ]),
                                          ]),
                                          _c("p"),
                                          _c("p", [
                                            _c("u", [
                                              _vm._v(
                                                "Want to add jobs in offline mode?"
                                              ),
                                            ]),
                                          ]),
                                          _c("ul", [
                                            _c("li", [
                                              _vm._v("Referesh the Page"),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                "Add jobs in offline mode"
                                              ),
                                            ]),
                                            _c("li", [
                                              _vm._v(
                                                "Once network service is available, you will get option to publish these jobs to server"
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("PartialJobView", {
                            staticClass: "back-btn",
                            attrs: {
                              "btn-text": "Back to Wizard",
                              "job-id": _vm.selectedJobIdToExpand,
                            },
                            on: { closeJobView: _vm.closeJobView },
                          }),
                          _vm.showFloatingButton
                            ? _c("FloatingAddJobButton", {
                                attrs: { "offline-d-b": _vm.offlineDB },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.showConnectionStatusSnackBar
                        ? _c("ConnectionStatusBar")
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("Confirmation"),
          _c("Snackbar"),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 0, bottom: "", left: "" },
              model: {
                value: _vm.twilioSnackbar,
                callback: function ($$v) {
                  _vm.twilioSnackbar = $$v
                },
                expression: "twilioSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.twilioSnackbarText) + "   "),
              _c("Counter", { ref: "twilioSnackbarCounter" }),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clearTwilioSnackbar.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              staticClass: "mt-5",
              attrs: {
                absolute: "",
                "multi-line": true,
                "auto-height": "",
                color: _vm.snackbarColor
                  ? _vm.snackbarColor + "lighten-2"
                  : "red lighten-2",
                top: "",
                center: "",
              },
              model: {
                value: _vm.errorSnackbar,
                callback: function ($$v) {
                  _vm.errorSnackbar = $$v
                },
                expression: "errorSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.errorSnackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { color: "white", flat: "" },
                  on: { click: _vm.clearErrorSnackbar },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                "multi-line": true,
                timeout: 0,
                bottom: "",
                left: "",
                color: "red",
              },
              model: {
                value: _vm.statusUpdateSnackbar,
                callback: function ($$v) {
                  _vm.statusUpdateSnackbar = $$v
                },
                expression: "statusUpdateSnackbar",
              },
            },
            [
              _vm._v(" You have been logged out of the queue! "),
              _c(
                "v-btn",
                {
                  attrs: { color: "white", flat: "" },
                  on: { click: _vm.reconnectWorker },
                },
                [_vm._v(" Reconnect "), _c("v-icon", [_vm._v("refresh")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: 6000, bottom: "", left: "" },
              model: {
                value: _vm.clockInSnackbar,
                callback: function ($$v) {
                  _vm.clockInSnackbar = $$v
                },
                expression: "clockInSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.clockInSnackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  on: { click: _vm.clearClockInSnackbar },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _vm.haveAccessToken
            ? _c("GlobalNotificationsHandler", {
                on: {
                  newAppVersionFound: function ($event) {
                    _vm.refreshAppSnackbar = true
                  },
                },
              })
            : _vm._e(),
          _vm.logoutUrl
            ? _c("iframe", {
                staticClass: "hide",
                attrs: { src: _vm.logoutUrl, height: "5", width: "5" },
              })
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                "content-class": "info-dialog",
                persistent: "",
              },
              model: {
                value: _vm.showInactivityConfirmation,
                callback: function ($$v) {
                  _vm.showInactivityConfirmation = $$v
                },
                expression: "showInactivityConfirmation",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", {}, [
                    _c("h3", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v("Session Timeout"),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _c("p", { staticClass: "mb-0 sessionOutText" }, [
                      _vm._v(
                        " You are being logged out automatically due to inactivity. Please log in again. "
                      ),
                    ]),
                    _c("p", { staticClass: "mb-0 sessionOutText" }, [
                      _c("b", [
                        _vm._v(" You will be automatically logged out in "),
                        _c("span", [
                          _vm._v(
                            "( " + _vm._s(_vm.getAutoLogoutTimeLeft) + " )"
                          ),
                        ]),
                        _vm._v(" seconds. "),
                      ]),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "text-center pa-3" },
                    [
                      _c("v-spacer"),
                      !_vm.incidentManagerShiftDialog
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "m-auto",
                              attrs: { color: "primary" },
                              on: {
                                click: _vm.resetInactivityConfirmationSettings,
                              },
                            },
                            [_vm._v(" Stay Logged-In ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-auto",
                          attrs: { color: "primary" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("Logout Now")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                "content-class": "info-dialog",
                persistent: "",
              },
              model: {
                value: _vm.showInactivityComplete,
                callback: function ($$v) {
                  _vm.showInactivityComplete = $$v
                },
                expression: "showInactivityComplete",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", {}, [
                    _c("h3", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v("Session Timeout"),
                    ]),
                  ]),
                  _c("v-divider"),
                  _c("v-card-text", [
                    _c("p", { staticClass: "mb-3 sessionOutText" }, [
                      _vm._v(
                        "You have been logged off automatically. You will be redirected shortly..."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.incidentManagerShiftDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.incidentManagerShiftDialog,
                callback: function ($$v) {
                  _vm.incidentManagerShiftDialog = $$v
                },
                expression: "incidentManagerShiftDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [_c("v-toolbar-title", [_vm._v("Shift Time")])],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 pt-0 scroll-content-dialog" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { sm6: "", "pr-2": "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "shiftStartTimePickerDialog",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.startTime,
                                    "nudge-right": 40,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.startTime = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.startTime = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    label: "Start time",
                                                    "append-icon":
                                                      "access_time",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: _vm.startTime,
                                                    callback: function ($$v) {
                                                      _vm.startTime = $$v
                                                    },
                                                    expression: "startTime",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3975914553
                                  ),
                                  model: {
                                    value: _vm.showShiftStartTimePicker,
                                    callback: function ($$v) {
                                      _vm.showShiftStartTimePicker = $$v
                                    },
                                    expression: "showShiftStartTimePicker",
                                  },
                                },
                                [
                                  _c(
                                    "v-time-picker",
                                    {
                                      attrs: {
                                        format: "24hr",
                                        min: _vm.minShiftStart,
                                        "full-width": "",
                                      },
                                      model: {
                                        value: _vm.startTime,
                                        callback: function ($$v) {
                                          _vm.startTime = $$v
                                        },
                                        expression: "startTime",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showShiftStartTimePicker = false
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.shiftStartTimePickerDialog.save(
                                                _vm.startTime
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("OK")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "", "pl-2": "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "shiftEndTimePickerDialog",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "return-value": _vm.endTime,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px",
                                    "nudge-right": 40,
                                  },
                                  on: {
                                    "update:returnValue": function ($event) {
                                      _vm.endTime = $event
                                    },
                                    "update:return-value": function ($event) {
                                      _vm.endTime = $event
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  staticClass: "required",
                                                  attrs: {
                                                    label: "End time",
                                                    "append-icon":
                                                      "access_time",
                                                    readonly: "",
                                                    required: "",
                                                    "data-vv-name":
                                                      "TimePicker",
                                                    "error-messages":
                                                      _vm.validationMessage(
                                                        "TimePicker"
                                                      ),
                                                    "data-vv-scope":
                                                      "frmshiftTime",
                                                  },
                                                  model: {
                                                    value: _vm.endTime,
                                                    callback: function ($$v) {
                                                      _vm.endTime = $$v
                                                    },
                                                    expression: "endTime",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1687723373
                                  ),
                                  model: {
                                    value: _vm.showShiftEndTimePicker,
                                    callback: function ($$v) {
                                      _vm.showShiftEndTimePicker = $$v
                                    },
                                    expression: "showShiftEndTimePicker",
                                  },
                                },
                                [
                                  _c(
                                    "v-time-picker",
                                    {
                                      attrs: {
                                        format: "24hr",
                                        min: _vm.startTime,
                                        "full-width": "",
                                      },
                                      model: {
                                        value: _vm.endTime,
                                        callback: function ($$v) {
                                          _vm.endTime = $$v
                                        },
                                        expression: "endTime",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showShiftEndTimePicker = false
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.shiftEndTimePickerDialog.save(
                                                _vm.endTime
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("OK")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.wrongTimeRangeError
                        ? _c("v-flex", { attrs: { "error--text": "" } }, [
                            _vm._v(" " + _vm._s(_vm.wrongTimeRangeError) + " "),
                          ])
                        : _vm._e(),
                      _c("p", { staticClass: "mb-0 sessionOutText" }, [
                        _c("b", [
                          _vm._v(" You will be automatically logged out in "),
                          _c("span", [
                            _vm._v(
                              "(" + _vm._s(_vm.getInactivityTimeLeft) + ")"
                            ),
                          ]),
                          _vm._v(" , please enter your shift. "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.addIncidentManagerShiftTime },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, bottom: "", left: "" },
          model: {
            value: _vm.imageSnackbar,
            callback: function ($$v) {
              _vm.imageSnackbar = $$v
            },
            expression: "imageSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.imageSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.imageSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 0, bottom: "", left: "", color: "red" },
          model: {
            value: _vm.refreshAppSnackbar,
            callback: function ($$v) {
              _vm.refreshAppSnackbar = $$v
            },
            expression: "refreshAppSnackbar",
          },
        },
        [
          _c("span", [_vm._v("New updates available! Refresh your page.")]),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "white" },
              nativeOn: {
                click: function ($event) {
                  return _vm.refreshApplication.apply(null, arguments)
                },
              },
            },
            [
              _c("v-icon", { staticClass: "md-icon" }, [_vm._v("replay")]),
              _vm._v(" Refresh "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000, bottom: "", left: "" },
          model: {
            value: _vm.notificationSnackbar,
            callback: function ($$v) {
              _vm.notificationSnackbar = $$v
            },
            expression: "notificationSnackbar",
          },
        },
        [
          _c("span", [_vm._v("You have a New Notification.")]),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.notificationSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 5000, bottom: "", left: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.snackbarMessage))]),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.addEmergencyInfoDialog && _vm.emergencyAddedFromAnswer.length > 0
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "600" },
              model: {
                value: _vm.addEmergencyInfoDialog,
                callback: function ($$v) {
                  _vm.addEmergencyInfoDialog = $$v
                },
                expression: "addEmergencyInfoDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c(
                      "h3",
                      [
                        _vm.emergencyAddedFromAnswer.length === 1
                          ? _c("div", [_vm._v("New Emergency has been added.")])
                          : _c("div", [
                              _vm._v("New Emergencies have been added."),
                            ]),
                        _c("br"),
                        _vm._l(
                          _vm.emergencyAddedFromAnswer,
                          function (emergency, index) {
                            return _c(
                              "ul",
                              { key: index, staticClass: "ml-1" },
                              [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(emergency.typeDescription) +
                                      " (" +
                                      _vm._s(emergency.detailDescription) +
                                      ")"
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        _c("br"),
                        _c("div", [
                          _vm._v(
                            "Please provide answers for emergency questions."
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.closeEmergencyInfoDialog },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.callAlert
        ? _c("IncomingCallModal", {
            attrs: { "call-alert": _vm.callAlert },
            on: { closeModal: () => (_vm.callAlert = null) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }