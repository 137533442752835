import ContractorController from '@/api/contractorController'
import DeploymentController from '@/api/deployment-controller'
import EngineerController from '@/api/engineerController'
import { JobVisitStatus } from '@/common/enums'
import { AppointmentDetail } from '@/api/models/deployment/contractor/appointment-detail'
import { AutoDeployment, ContractorAppointment, Delay } from '@/models/autoDeployment/auto-deployment'
import EngineerModel from '@/models/contractor/EngineerModel'
import UpdateContractorAppointedDetail from '@/models/requests/UpdateContractorAppointedDetail'
import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

export const useDeploymentStore = defineStore('Deployment', () => {
  const autoDeployment = reactive<AutoDeployment>(new AutoDeployment())
  const engineers = ref<EngineerModel[]>([])
  const jobOffer = ref<AppointmentDetail | null>(null)
  const isJobOfferAccepted = ref<boolean>(false)
  const isJobOfferRejected = ref<boolean>(false)
  const jobId = ref<string | null>(null)
  const appointmentId = ref<string | null>(null)

  async function retrieveAutoDeploymentDetail(jobId: string, deploymentId: string) {
    Object.assign(autoDeployment, await DeploymentController.GetAutoDeploymentDetail(jobId, deploymentId))
  }

  function updateContractorAppointment(contractor: { id: string; appointment: ContractorAppointment }) {
    const selectedContractor = autoDeployment.contractors?.find((x) => x.contractorId === contractor.id)
    if (!selectedContractor) {
      return
    }
    selectedContractor.appointmentRequest = contractor.appointment
  }

  function updateDeploymentDelay(delays: Delay[]) {
    if (!autoDeployment) {
      return
    }
    autoDeployment.tradeDelays = delays
  }

  async function retrieveEngineers(contractorId: string) {
    engineers.value = await EngineerController.GetContractorEngineers(contractorId)
  }
  async function retrieveJobOffer(jobId: string, appointmentId: string) {
    jobOffer.value = await ContractorController.GetContactorJobOfferDetail(jobId, appointmentId)
  }

  async function assignEngineerToJob(engineerId: string): Promise<boolean> {
    if (!appointmentId.value || !jobId.value) {
      return false
    }
    const updateContractorAppointed = new UpdateContractorAppointedDetail(appointmentId.value, jobId.value)
    updateContractorAppointed.engineerId = engineerId
    updateContractorAppointed.status = JobVisitStatus[JobVisitStatus.Accepted]

    await EngineerController.AssignEngineerToJob(updateContractorAppointed)
    return true
    // TODO: update status on the stored contractor appointed detail
  }

  // Default pinia reset function only works with options pattern.
  function $reset() {
    Object.assign(autoDeployment, new AutoDeployment())
    engineers.value = []
    jobOffer.value = null
    isJobOfferAccepted.value = false
    isJobOfferRejected.value = false
    jobId.value = null
    appointmentId.value = null
  }

  return {
    autoDeployment,
    engineers,
    jobOffer,
    isJobOfferAccepted,
    isJobOfferRejected,
    jobId,
    appointmentId,
    updateDeploymentDelay,
    updateContractorAppointment,
    retrieveAutoDeploymentDetail,
    retrieveEngineers,
    assignEngineerToJob,
    retrieveJobOffer,
    $reset,
  }
})
