var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "close-on-content-click": true,
        "nudge-width": 200,
        "content-class": "auth-header",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", large: "" } }, on),
                [
                  _c("v-avatar", { attrs: { size: "38px" } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.userIconUrl,
                        alt: _vm.userName,
                        color: "primary",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { staticClass: "login-menu" },
        [
          _c("v-list", { staticClass: "clearfix" }, [
            _c("div", { staticClass: "profile-outer-block" }, [
              _c("div", { staticClass: "profile-detail-block" }, [
                _c(
                  "div",
                  { staticClass: "profile-img-block" },
                  [
                    _c("ProfileImage", {
                      attrs: { image: _vm.userIconUrl, size: "small" },
                      on: { "update:image": _vm.onProfilePictureChanged },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "profile-right" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.userName))]),
                  _c("h5", { staticClass: "secondary--text" }, [
                    _vm._v(_vm._s(_vm.userRoleDescription)),
                  ]),
                  _c("a", { attrs: { href: _vm.mailToLink } }, [
                    _vm._v(" " + _vm._s(_vm.userEmail) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("v-divider", { attrs: { light: "" } }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.isUserRoleContractor
                ? _c(
                    "router-link",
                    { attrs: { to: "contractorProfile" } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("settings"),
                      ]),
                      _vm._v(" Settings "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isLoggedIn
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        disabled: _vm.loggingOut,
                        loading: _vm.loggingOut,
                      },
                      on: { click: _vm.logout },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("logout")]),
                      _vm._v(" Logout "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }