var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-expansion-panel",
    {
      attrs: { expand: "" },
      model: {
        value: _vm.store.paymentOpened,
        callback: function ($$v) {
          _vm.$set(_vm.store, "paymentOpened", $$v)
        },
        expression: "store.paymentOpened",
      },
    },
    [
      _c("v-expansion-panel-content", [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c("h3", [_vm._v("Payment")]),
        ]),
        _c("div", [
          _vm.store.isTransactionCompleted
            ? _c("div", { staticClass: "px-4 py-2 pb-3" }, [
                _c(
                  "span",
                  { staticClass: "complete-text font-weight-bold green--text" },
                  [_vm._v(" ✓   Payment successful ")]
                ),
              ])
            : _c("div", [
                !_vm.store.paymentDetailsConfirmed
                  ? _c(
                      "div",
                      [
                        true
                          ? _c(
                              "v-layout",
                              {
                                attrs: { wrap: "", grey: "", "lighten-4": "" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text",
                                  },
                                  [
                                    _vm._v(
                                      " !   You must confirm payment details before taking the payment "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        !_vm.callSid
                          ? _c(
                              "v-layout",
                              {
                                attrs: { wrap: "", grey: "", "lighten-4": "" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text",
                                  },
                                  [
                                    _vm._v(
                                      " !   You must be on a call to take payment details "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "v-layout",
                              [
                                _c("AgentAssist", {
                                  attrs: {
                                    "call-sid": _vm.callSid,
                                    job: _vm.job,
                                  },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
              ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }