var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "wrapper", attrs: { id: _vm.mapOptions.mapId } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }