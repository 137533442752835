var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return Boolean(_vm.deployment.id)
    ? _c(
        "v-layout",
        {
          staticClass: "wrapper",
          attrs: { row: "", "fill-height": "", "pa-2": "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { xs6: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "font-weight-bold mb-1",
                      attrs: { xs3: "" },
                    },
                    [_vm._v("Status:")]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            small: "",
                            color:
                              _vm.deploymentStatusMap[_vm.deployment.status]
                                .color || "blue",
                            "text-color": "white",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.deploymentStatusMap[_vm.deployment.status]
                                .text || "Unknown"
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "font-weight-bold mb-1 mr-1",
                      attrs: { xs3: "" },
                    },
                    [_vm._v(" Postcode:")]
                  ),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _vm._v(" " + _vm._s(_vm.deployment.postcode) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "font-weight-bold mb-1 mr-1",
                      attrs: { xs3: "" },
                    },
                    [_vm._v("Trade:")]
                  ),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.deployment.trade || "Not Set") + " "
                    ),
                  ]),
                ],
                1
              ),
              _vm.isDelaysApplied
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-bold mb-1",
                          attrs: { xs12: "" },
                        },
                        [_vm._v("Delays")]
                      ),
                      _vm._l(
                        _vm.filterDelays(_vm.deployment.tradeDelays),
                        function (delay, index) {
                          return _c(
                            "v-flex",
                            { key: `${delay.delayCodeId}-${index}` },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h5", [_vm._v(_vm._s(delay.description))]),
                              ]),
                              _c(
                                "v-flex",
                                _vm._l(
                                  delay.delayRules,
                                  function (delayRule, delayRuleIndex) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: `${delayRule.rule}-${delayRuleIndex}`,
                                        staticClass: "mr-1 mb-1",
                                        attrs: {
                                          small: "",
                                          color: "red",
                                          "text-color": "white",
                                        },
                                      },
                                      [_vm._v(_vm._s(delayRule.rule))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-flex", { attrs: { xs6: "" } }, [
            _c(
              "div",
              { staticClass: "pa-2" },
              [
                _vm.googleMapOptions
                  ? _c("InteractiveMap", {
                      attrs: { "map-options": _vm.googleMapOptions },
                    })
                  : _c("h2", [_vm._v("Unable to load map")]),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }