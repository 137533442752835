<template>
  <v-layout v-if="!contractorAppointment">No appointed contractor details found</v-layout>
  <v-layout v-else row fill-height class="wrapper">
    <v-layout column xs6>
      <v-layout>
        <v-flex class="ml-4 mb-2">
          <div v-if="contractorAppointment.notifications.length > 0">
            <v-layout row class="pa-1">
              <v-flex xs6>
                <h5 class="font-weight-bold">Notifications</h5>
              </v-flex>
            </v-layout>

            <v-layout
              v-for="(notification, index) in contractorAppointment.notifications"
              :key="`${notification.type}-${index}`"
              column
              row
              class="px-1"
            >
              <v-layout>
                <v-flex xs6>
                  <h5> {{ notification.type }} Sent At: </h5>
                </v-flex>
                <v-flex xs6>
                  <h5>{{ formatDateTime(notification.date) }}</h5>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs12>
                  <h5>
                    {{ notification.type }}
                    {{ splitContactInfo(notification.contactInfo).length > 1 ? 'Recipients' : 'Recipient' }}
                  </h5>
                </v-flex>
                <v-flex xs12>
                  <h5
                    v-for="(data, contactInfoIndex) in formatContactInfo(notification.contactInfo)"
                    :key="`${data}-${contactInfoIndex}`"
                    >{{ data }}</h5
                  >
                </v-flex>
              </v-layout>
            </v-layout>
          </div>
          <v-layout row class="pa-1">
            <v-flex xs6>
              <h5 class="font-weight-bold">Status:</h5>
            </v-flex>
            <v-flex xs6>
              <v-chip small :color="statusColor" text-color="white"> {{ formattedStatusText }}</v-chip>
            </v-flex>
          </v-layout>

          <v-layout v-if="contractorAppointment?.deploymentRules.length" row class="pa-1">
            <v-flex xs6>
              <h5 class="font-weight-bold">Deployment Rules:</h5>
            </v-flex>
            <v-flex xs6>
              <v-flex v-for="(rule, index) in contractorAppointment.deploymentRules" :key="`${rule}-${index}`">
                <h5 class="mb-1">{{ index + 1 }}. {{ rule }}</h5>
              </v-flex>
            </v-flex>
          </v-layout>

          <div v-if="isAcceptedOrRejected(contractorAppointment)">
            <v-layout row class="pa-1">
              <v-flex xs6>
                <h5 class="font-weight-bold">{{ formattedStatusText }} At:</h5>
              </v-flex>
              <v-flex xs6>
                <h5>{{ formatDateTime(contractorAppointment.updatedAt) }}</h5>
              </v-flex>
            </v-layout>

            <v-layout row class="pa-1">
              <v-flex xs6>
                <h5 class="font-weight-bold">{{ formattedStatusText }} By:</h5>
              </v-flex>
              <v-flex xs6>
                <h5>{{ contractorAppointment.updatedByUserName }}</h5>
              </v-flex>
            </v-layout>
          </div>

          <v-layout v-if="Boolean(contractorAppointment.updateReasonType)" row class="pa-1">
            <v-flex xs6>
              <h5 class="font-weight-bold">{{ formattedStatusText }} Reason:</h5>
            </v-flex>
            <v-flex xs6>
              <h5>{{ contractorAppointment.updateReasonType }}</h5>
              <h5 v-if="Boolean(contractorAppointment.updateReasonDescription)">{{
                contractorAppointment.updateReasonDescription
              }}</h5>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  AppointmentRequestStatus,
  AppointmentStatusMap,
  ContractorAppointment,
} from '@/models/autoDeployment/auto-deployment'
import { formatDateTime } from '@/common/datetime'
import InteractiveMap from '@/components/InteractiveMap.vue'
import { useDeploymentStore } from '@/pinia/deployment'

@Component({
  components: {
    InteractiveMap,
  },
})
export default class AutoDeploymentContractorDetail extends Vue {
  @Prop()
  public contractorAppointedId: string

  @Prop()
  public deploymentId: string

  public formatDateTime = formatDateTime
  public deploymentStatusMap = AppointmentStatusMap

  public deploymentStatusEnum = AppointmentRequestStatus

  private contactInfoDisplayLimit = 3

  public get contractorAppointment(): ContractorAppointment | null {
    const contractor = useDeploymentStore().autoDeployment?.contractors?.find(
      (x) => x.contractorId === this.contractorAppointedId
    )
    return contractor?.appointmentRequest || null
  }

  public isAcceptedOrRejected(appointment: ContractorAppointment): boolean {
    const { status } = appointment
    return status === this.deploymentStatusEnum.Accepted || status === this.deploymentStatusEnum.Rejected
  }

  public get statusColor(): string {
    return this.contractorAppointment ? AppointmentStatusMap[this.contractorAppointment.status].color : ''
  }

  public get formattedStatusText(): string {
    return this.contractorAppointment ? AppointmentStatusMap[this.contractorAppointment.status].text : ''
  }

  public splitContactInfo(contactInfo: string): string[] {
    return contactInfo.split(/[,;]/)
  }

  public formatContactInfo(contactInfo: string) {
    const contacts = this.splitContactInfo(contactInfo)
    const numberOfContacts = contacts.length

    // If the number of contacts to display is greater than contactInfoDisplayLimit, we display how many there are.
    // Else we show the actual contact details
    return numberOfContacts > this.contactInfoDisplayLimit ? [numberOfContacts] : contacts
  }
}
</script>

<style scoped>
.v-chip--small {
  height: 12px !important;
  font-size: 12px;
  padding: 10px 0px;
  margin: 0;
}
</style>
