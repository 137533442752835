import CosmosAdminDbRecordBase from '@/models/CosmosAdminDbRecordBase'
export default class ClientUserDetailModel extends CosmosAdminDbRecordBase {
  public firstName: string
  public lastName: string
  public email: string
  public hasPortalAccess: boolean
  public isActive: boolean
  public insurerId: number
  public profileImageUrl: string | undefined
  public profileImageThumbnailUrl: string | undefined

  public isAffinityPartner: boolean
  public affinityPartnerPolicyScheduleIds?: number[]

  public constructor() {
    super()
    this.hasPortalAccess = true
  }
}
